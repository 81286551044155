import differenceBy from 'lodash/differenceBy';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import isEqual from 'lodash/isEqual';
import isNull from 'lodash/isNull';
import keys from 'lodash/keys';
import omitBy from 'lodash/omitBy';
import set from 'lodash/set';

import {getTravelOrderStopPODsByID} from 'core/entities/TravelOrder/modules/travelOrderStops';
import TravelOrder from 'core/entities/TravelOrder/types';
import {StopInfoParams} from 'core/entities/TravelOrder/types/StopInfoParams';

import {getAttachmentsMetaData} from 'pages/TravelOrders/redux/mappers/attachments';
import {EditPODFormValues} from 'pages/TravelOrders/types/formTypes';

import {createDate} from 'utils/dateTime';

import {getCorrespondingAttachments} from '../attachments';

export const computeDeletedFilesIDsInTheEditPODsForm = (params: StopInfoParams & {podData: EditPODFormValues}) => {
    const {podData, travelOrder, stopID} = params;

    const toStopPODs = getTravelOrderStopPODsByID({stopID, travelOrder});
    const podsFromTheForm = podData.pods || [];

    const deletedFilesIDsInTheEditPODsForm = toStopPODs.map((pod) => {
        const currentPODFromTheForm = podsFromTheForm.find((podFromTheForm) => podFromTheForm.podID === pod.id);

        const currentPODFilesFromTheForm = (currentPODFromTheForm?.attachments || []).filter(
            (attachment) => 'id' in attachment,
        );

        const deletedFiles = differenceBy(pod.files, currentPODFilesFromTheForm, 'id');
        const deletedFileIDs = deletedFiles.map((deletedFile) => deletedFile.id);

        if (!deletedFileIDs.length) {
            return null;
        }

        return deletedFileIDs;
    });

    return flatten(deletedFilesIDsInTheEditPODsForm).filter(Boolean);
};

export const transformEditPODsDataToRequestBody = ({
    podData,
    travelOrder,
    stopID,
}: {
    podData: EditPODFormValues;
    travelOrder?: TravelOrder | null;
    stopID?: string;
}) => {
    const {date, time, timeZone, pods} = podData;

    const createdDateTimeInfo = createDate(`${date} ${time}`, {fromTimeZone: timeZone, toTimeZone: 'utc'});
    const {fullOriginalDateTime: dateTime, isValid} = createdDateTimeInfo;

    const deletedFilesIDs = computeDeletedFilesIDsInTheEditPODsForm({podData, travelOrder, stopID});

    const podsForRequest = (pods || []).map((pod) => {
        const files = pod?.attachments || [];

        const convertedAttachments = getAttachmentsMetaData(files);

        const data = {
            files: convertedAttachments,
            signedBy: pod.signedBy || null,
            bolId: pod.bolId?.value || null,
            podId: pod.podID || null,
        };

        return omitBy(data, isNull);
    });

    return {
        documentActionTimeZone: isValid && timeZone ? timeZone : null,
        documentActionDateTime: isValid ? dateTime : null,
        pods: podsForRequest,
        deleteFileIds: deletedFilesIDs.length ? deletedFilesIDs : undefined,
    };
};

export const transformEditPODsFilesToRequestBody = (params: {
    newFilesData: {[key: string]: {fileId: number; podId: number}}[];
    podData: EditPODFormValues;
}) => {
    const {podData, newFilesData} = params;

    const podsFilesFromTheForm = (podData?.pods || []).map((pod) => pod.attachments || []).flat();

    const newTmpIDs = keys(newFilesData);

    const transformPodIDsData = groupBy(
        newTmpIDs.map((tmpID) => {
            const podsFileIDs: {fileId: number; podId: number} = newFilesData[tmpID];
            return set(podsFileIDs, 'tmpID', tmpID) as {fileId: number; podId: number; tmpID: string};
        }),
        'podId',
    );

    const newPodIDs = keys(transformPodIDsData);

    return newPodIDs.map((podID) => {
        const podsFileIDs = transformPodIDsData[podID];
        const correspondingFiles = podsFilesFromTheForm.filter((item) =>
            podsFileIDs.find((itm) => 'tmpID' in item && isEqual(itm.tmpID, item.tmpID)),
        );
        const filesIds = Object.fromEntries(podsFileIDs.map((n) => [n.fileId, n.tmpID]));
        const {uploadedFormData} = getCorrespondingAttachments({formAttachments: correspondingFiles, filesIds});

        return {podID, uploadedFormData};
    });
};
