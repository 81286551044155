import {FilterOptions} from 'pages/LoadBoard/redux/reducers/quoteReceivers';

export const driverFilterOptions: Record<
    | 'allowedSendToDriver'
    | 'notAllowedSendToDriver'
    | 'driverReadQuote'
    | 'driverDeclinedQuote'
    | 'driverProvidedQuote',
    FilterOptions
> = {
    allowedSendToDriver: 'ALLOWED_SEND_TO_DRIVER',
    notAllowedSendToDriver: 'NOT_ALLOWED_SEND_TO_DRIVER',
    driverReadQuote: 'DRIVER_READ_QUOTE',
    driverDeclinedQuote: 'DRIVER_DECLINED_QUOTE',
    driverProvidedQuote: 'DRIVER_PROVIDED_QUOTE',
} as const;
