import React from 'react';
import {Field} from 'redux-form';

import Select from './components/Select';

import {SingleSelectProps} from './types';

const SingleSelect: React.FC<SingleSelectProps> = (props) => {
    const {
        isFirstOptionSelected = false,
        closeMenuOnSelect = true,
        menuPlacement = 'auto',
        isClearable = false,
        isDisabled = false,
        placeholder = '',
        externalOnChange,
        formatGroupLabel,
        errorPlacement,
        validate,
        options,
        label,
        name,
    } = props;

    return (
        <>
            {label ? <label className="form-label">{label}</label> : null}

            <Field
                isFirstOptionSelected={isFirstOptionSelected}
                closeMenuOnSelect={closeMenuOnSelect}
                externalOnChange={externalOnChange}
                formatGroupLabel={formatGroupLabel}
                errorPlacement={errorPlacement}
                menuPlacement={menuPlacement}
                isClearable={isClearable}
                placeholder={placeholder}
                isDisabled={isDisabled}
                validate={validate}
                component={Select}
                options={options}
                name={name}
            />
        </>
    );
};

export default SingleSelect;
