import {routerMiddleware} from 'connected-react-router';
import isFunction from 'lodash/isFunction';
import {createLogger} from 'redux-logger';
import thunk from 'redux-thunk';

import config from 'config';

import * as api from 'services/restapi';

import chatWebSocketMiddleware from 'widgets/Chat/middlewares/chatWebSocketMiddleware';

import routerLocationMiddleware from './routerLocation';

export const createMiddlewares = (history) => {
    let middlewares: any[] = [];

    middlewares.push(chatWebSocketMiddleware);
    middlewares.push(thunk.withExtraArgument({api}));
    middlewares.push(routerLocationMiddleware);
    middlewares.push(routerMiddleware(history));

    if (process.env.NODE_ENV !== `test`) {
        const useLogger = config.NODE_ENV === 'development' || localStorage.getItem('enable_debugger') === '1';

        if (useLogger) {
            const logger: any = createLogger({
                predicate: (getState, action) => action && action.type && !action.type.includes('@@redux-form'),
            });

            middlewares.push(logger);
        }
    }

    // for avoid bug with jest when some middlewares equal undefined
    if (process.env.NODE_ENV === `test`) {
        middlewares = middlewares.filter(isFunction);
    }

    return middlewares;
};
