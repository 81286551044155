import React, {useEffect} from 'react';
import {connect, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import trim from 'lodash/trim';
import {Field, formValueSelector, reduxForm} from 'redux-form';

import {AppState} from 'store';
import {getCurrentDispatcher} from 'store/reducers/userData/selectors';

import {FIELDS} from 'core/entities/Customer/constants/fields';
import validate from 'core/entities/Customer/validation';
import asyncValidate from 'core/entities/Customer/validation/asyncValidation';
import {checkHasAccountingRole, checkHasAdminRole} from 'core/entities/Dispatcher/roles';

import FormActionButtons from 'components/ui/Form/FormActionButtons';
import InputWithPlacesAutocomplete from 'components/ui/Form/InputWithPlacesAutocomplete';
import PhoneWithLocalization from 'components/ui/Form/PhoneWithLocalization';
import RadioInput from 'components/ui/Form/RadioInput';
import RequiredLabel from 'components/ui/Form/RequiredLabel';
import SingleSelect from 'components/ui/Form/SingleSelect';
import TextInput from 'components/ui/Form/TextInput';

import {removeNonDigitChars} from 'utils';
import capitalize from 'utils/capitalize';
import identifierTypes from 'utils/data/identifierTypes';
import {activeItemsWithOnHold, billingMethod, paymentTerms} from 'utils/data/radioInputItems';

import useCurrentRegion from 'hooks/useCurrentRegion';
import withNavigationConfirm from 'hocs/withNavigationConfirm';

import CustomerTypes from '../../../common/CustomerTypes/components/CustomerTypesSelector';
import CustomerApiBroker from '../components/CustomerApiBroker';
import LoadBoardsInformationEU from '../components/localization/LoadBoardsInformation/LoadBoardsInformationEU';

import BrokerAdditionalInfo from './components/BrokerAdditionalInfo';
import IdentifierTypes from './components/IdentifierTypes';

import {ON_HOLD_REASONS} from './constants';

interface OwnProps {
    form: string;
}

const General = (props) => {
    const {
        form,
        currentDispatcher,
        isActiveCustomer,
        isDisabledForGlobal,
        handleSubmit,
        submitTitle,
        pristine,
        submitting,
        customerUrl,
        identifier_type,
        reset,
        isSubmitDisabled,
    } = props;

    const history = useHistory();
    const {isEurope} = useCurrentRegion();

    const canDispatcherPutOnHold = checkHasAccountingRole(currentDispatcher) || checkHasAdminRole(currentDispatcher);

    const handleSelectZip = (place) => {
        props.change(FIELDS.billing_city, place.address.city);
        props.change(FIELDS.billing_state, place.address.state);
        props.change(FIELDS.billing_zip, place.address.zip);
        props.change(FIELDS.billing_country, place.address.country);
    };

    const stateLabel = isEurope ? 'State' : RequiredLabel('State');
    const zipLabel = isEurope ? 'Zip' : RequiredLabel('Zip');

    const dispatch = useDispatch();

    useEffect(() => {
        if (!identifier_type && isEurope) {
            props.change(FIELDS.identifier_type, identifierTypes.REG.shortName);
        }
    }, [identifier_type]);

    useEffect(() => {
        dispatch(reset(form));
    }, []);

    return (
        <div className="broker-update__content">
            <form onSubmit={handleSubmit} className="bcf-tab1">
                <div className="bcf__main-info modal-body__custom-scroll">
                    <div className="bcf__main-info_item">
                        <h3>General Information:</h3>
                        <div className="row">
                            <div className="col-md-6 col-lg-3">
                                <Field
                                    name={FIELDS.name}
                                    type="text"
                                    component={TextInput}
                                    label={RequiredLabel('Company Name')}
                                    maxLength={100}
                                    placeholder="enter company name"
                                    normalize={capitalize}
                                    disabled={isDisabledForGlobal}
                                />
                            </div>
                            {canDispatcherPutOnHold && (
                                <div className="col-md-6 col-lg-3">
                                    <Field
                                        name={FIELDS.is_active}
                                        component={RadioInput}
                                        label="Status"
                                        items={activeItemsWithOnHold}
                                        disabled={isDisabledForGlobal}
                                    />
                                </div>
                            )}
                            {canDispatcherPutOnHold && !isActiveCustomer && (
                                <div className="col-md-6 col-lg-3">
                                    <SingleSelect
                                        name={FIELDS.on_hold_reason}
                                        label="On Hold Reason"
                                        options={ON_HOLD_REASONS}
                                        isDisabled={isDisabledForGlobal}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="bcf__main-info_item">
                        <div className="row">
                            {isEurope ? (
                                <div className="col-md-6 col-lg-3">
                                    <Field
                                        name={FIELDS.identifier_number}
                                        type="text"
                                        component={TextInput}
                                        label="REG#"
                                        maxLength={14}
                                        placeholder="enter registration number"
                                        normalize={removeNonDigitChars}
                                        disabled={isDisabledForGlobal}
                                    />
                                </div>
                            ) : (
                                <>
                                    <div className="col-md-6 col-lg-3">
                                        <Field
                                            name={FIELDS.identifier_number}
                                            type="text"
                                            component={TextInput}
                                            label="MC/FF/MX#"
                                            maxLength={14}
                                            placeholder="enter MC/FF/MX#"
                                            normalize={removeNonDigitChars}
                                            disabled={isDisabledForGlobal}
                                        />
                                    </div>
                                    <div className="col-md-6 col-lg-3">
                                        <IdentifierTypes name={FIELDS.identifier_type} disabled={isDisabledForGlobal} />
                                    </div>
                                </>
                            )}
                        </div>
                        <CustomerTypes form={form} />
                        <div className="row">
                            <div className="col-6">
                                <CustomerApiBroker form={form} disabled={isDisabledForGlobal} />
                            </div>
                            <div className="col-6">
                                <BrokerAdditionalInfo form={form} disabled={isDisabledForGlobal} />
                            </div>
                        </div>
                    </div>
                    <div className="bcf__main-info_item">
                        <h3>Billing Address:</h3>
                        <div className="row">
                            <div className="col-md-6 col-lg-3">
                                <Field
                                    name={FIELDS.billing_address_line}
                                    type="text"
                                    component={TextInput}
                                    placeholder="building number and street name"
                                    label={RequiredLabel('Address line 1')}
                                    normalize={(value) => (value && !value.trim() ? '' : value)}
                                    disabled={isDisabledForGlobal}
                                />
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <Field
                                    name={FIELDS.billing_additional_address_line}
                                    type="text"
                                    component={TextInput}
                                    placeholder="apartment#, suite#, box#, etc"
                                    label="Address line 2"
                                    disabled={isDisabledForGlobal}
                                />
                            </div>

                            <div className="col-md-12 col-lg-6">
                                <div className="row">
                                    <div className="col-3">
                                        <div className="city-state-block">
                                            <Field
                                                name={FIELDS.billing_city}
                                                type="text"
                                                component={TextInput}
                                                placeholder="enter city"
                                                label={RequiredLabel('City')}
                                                autocomplete="none"
                                                disabled={isDisabledForGlobal}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <div className="city-state-block">
                                            <Field
                                                name={FIELDS.billing_state}
                                                type="text"
                                                component={TextInput}
                                                placeholder="enter state"
                                                label={stateLabel}
                                                autocomplete="none"
                                                disabled={isDisabledForGlobal}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <div className="city-state-block">
                                            <Field
                                                name={FIELDS.billing_zip}
                                                type="text"
                                                component={InputWithPlacesAutocomplete}
                                                onSelectPlace={handleSelectZip}
                                                placeholder="enter zip"
                                                label={zipLabel}
                                                autocomplete="none"
                                                disabled={isDisabledForGlobal}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <div className="city-state-block">
                                            <Field
                                                name={FIELDS.billing_country}
                                                type="text"
                                                component={TextInput}
                                                placeholder="enter country"
                                                label={RequiredLabel('Country')}
                                                autocomplete="none"
                                                maxLength={40}
                                                disabled={isDisabledForGlobal}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bcf__main-info_item">
                        <h3>Contact Information:</h3>
                        <div className="row">
                            <div className="col-md-6 col-lg-3">
                                <Field
                                    name={FIELDS.main_phone}
                                    component={PhoneWithLocalization}
                                    label={RequiredLabel('Phone#')}
                                    disabled={isDisabledForGlobal}
                                />
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <Field
                                    name={FIELDS.fax}
                                    component={PhoneWithLocalization}
                                    label="Fax#"
                                    disabled={isDisabledForGlobal}
                                />
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <Field
                                    name={FIELDS.email}
                                    type="text"
                                    component={TextInput}
                                    placeholder="enter email address"
                                    label="email"
                                    normalize={trim}
                                    disabled={isDisabledForGlobal}
                                />
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <Field
                                    name={FIELDS.website}
                                    type="text"
                                    placeholder="enter company’s URL"
                                    component={TextInput}
                                    label="Website"
                                    disabled={isDisabledForGlobal}
                                />
                            </div>
                        </div>
                    </div>
                    {isEurope && (
                        <div className="carrier-main-info__item">
                            <LoadBoardsInformationEU disabled={false} />
                        </div>
                    )}
                    <div className="bcf__main-info_item">
                        <h3>Billing</h3>
                        <div className="row">
                            <div className="col-md-12 col-lg-3">
                                <div className="item-bg">
                                    <Field
                                        name={FIELDS.billing_method}
                                        component={RadioInput}
                                        label="billing method"
                                        items={billingMethod}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-9">
                                <div className="item-bg">
                                    <Field
                                        name={FIELDS.payment_terms}
                                        component={RadioInput}
                                        label="payment terms"
                                        items={paymentTerms}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <hr style={{marginBottom: 0, marginTop: 0}} />

                <div>
                    <FormActionButtons
                        disabled={isSubmitDisabled}
                        submitTitle={submitTitle}
                        onCancel={props.onCancel}
                        submitting={submitting}
                        entityUrl={customerUrl}
                        history={history}
                        reset={props.onReset}
                        pristine={pristine}
                        withRestore={true}
                        form={form}
                    />
                </div>
            </form>
        </div>
    );
};

const ReduxForm = reduxForm({
    validate,
    asyncValidate,
    asyncBlurFields: ['identifier_number'],
    asyncChangeFields: [],
});

export default connect((state: AppState, ownProps: OwnProps) => ({
    isActiveCustomer: formValueSelector(ownProps.form)(state, FIELDS.is_active),
    identifier_type: formValueSelector(ownProps.form)(state, FIELDS.identifier_type),
    isDisabledForGlobal: formValueSelector(ownProps.form)(state, FIELDS.isDispatchlandCustomer),
    currentDispatcher: getCurrentDispatcher(state),
}))(ReduxForm(withNavigationConfirm(General)));
