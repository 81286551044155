import {InferActions} from 'store';
import * as webSocketActionCreators from 'store/middlewares/mainAPISocketsIO/entities/loadBoardTrucks/actions';

import {DriverOffer, Quote} from 'core/entities/Quote/types';

import * as actionCreators from 'pages/LoadBoard/redux/actionCreators';
import * as types from 'pages/LoadBoard/redux/actionTypes';

import {addItem, updateItem} from 'utils/normalizer';

import {ListState} from './types';

type WebsocketAuctionWsActions = Pick<
    typeof actionCreators,
    | 'activatedQuoteWS'
    | 'addedDriverOfferWS'
    | 'updatedDriverOfferWS'
    | 'changeStatusDriverOfferWS'
    | 'createdQuoteGeneralNoteWS'
    | 'deletedQuoteGeneralNoteWS'
>;

type MainApiTruckReserveWsActions = Pick<
    typeof webSocketActionCreators,
    'trucksReserveChanged' | 'trucksReserveCanceled'
>;

type ActionsTypes = InferActions<WebsocketAuctionWsActions & MainApiTruckReserveWsActions>;

export default function websocketsReducer(state: ListState, action: ActionsTypes): ListState {
    switch (action.type) {
        case types.ACTIVATED_QUOTE_WS: {
            const {quoteID, status, notifiedDriversCount} = action.payload;
            const quote = state.quotes.byId[quoteID];
            if (!quote) {
                return state;
            }
            const updatedQuote = {
                ...quote,
                status,
                notifiedDriversCount,
            };
            return {
                ...state,
                quotes: updateItem(state.quotes, updatedQuote),
            };
        }

        case types.ADDED_DRIVER_OFFER_WS: {
            const driverOffer = action.payload;
            const quote = state.quotes.byId[driverOffer.quoteID];
            const isDriverOfferAlreadyInState = driverOffer.id in state.offers.byId;

            if (!driverOffer || !quote || isDriverOfferAlreadyInState) {
                return state;
            }

            const updatedQuote = {
                ...quote,
                allDriversOffersCount: quote.allDriversOffersCount + 1,
                newDriversOffersCount: quote.newDriversOffersCount + 1,
            };
            return {
                ...state,
                quotes: updateItem<Quote>(state.quotes, updatedQuote),
                offers: addItem<DriverOffer>(state.offers, driverOffer, {insertToBegin: true}),
            };
        }

        case types.UPDATED_DRIVER_OFFER_WS: {
            const driverOffer = action.payload;
            const offerForUpdate = state.offers.byId[driverOffer.id];
            const quote = state.quotes.byId[driverOffer.quoteID];

            if (!offerForUpdate || !quote) {
                return state;
            }

            const updatedOffer = {...offerForUpdate, ...driverOffer};
            const updatedOffers = updateItem<DriverOffer>(state.offers, updatedOffer);

            return {...state, offers: updatedOffers};
        }

        case types.CHANGED_DRIVER_OFFER_STATUS_WS: {
            const driverOffer = action.payload;
            const quote = state.quotes.byId[driverOffer.quoteID];
            const offerForUpdate = state.offers.byId[driverOffer.id];

            if (!quote || !offerForUpdate || offerForUpdate.status === driverOffer?.status) {
                return state;
            }

            const updatedOffer = {...offerForUpdate, status: driverOffer.status};
            const updatedOffers = updateItem<DriverOffer>(state.offers, updatedOffer);

            return {...state, offers: updatedOffers};
        }

        case types.CREATED_QUOTE_GENERAL_NOTE_WS: {
            const {generalNote} = action.payload;
            const quoteID = generalNote?.quoteID;
            const quote = state.quotes.byId[quoteID];
            if (!quote) {
                return state;
            }
            const currentGeneralNotes = quote.notes.general || [];
            const isGeneralNoteAlreadyExists = currentGeneralNotes?.some((n) => n.id === generalNote.id);
            if (isGeneralNoteAlreadyExists) {
                return state;
            }
            const updatedQuote = {
                ...quote,
                notes: {...quote.notes, general: [...currentGeneralNotes, generalNote]},
            };
            return {
                ...state,
                quotes: updateItem(state.quotes, updatedQuote),
            };
        }

        case types.DELETED_QUOTE_GENERAL_NOTE_WS: {
            const {generalNote} = action.payload;
            if (!generalNote) {
                return state;
            }
            const {id: noteID, quoteID} = generalNote;
            const quote = state.quotes.byId[quoteID];
            if (!quote) {
                return state;
            }
            const currentGeneralNotes = quote.notes.general || [];
            const updatedNotes = {...quote.notes, general: currentGeneralNotes.filter((n) => n.id !== noteID)};
            const updatedQuote = {...quote, notes: updatedNotes};
            return {
                ...state,
                quotes: updateItem(state.quotes, updatedQuote),
            };
        }

        default: {
            return state;
        }
    }
}
