import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import head from 'lodash/head';
import last from 'lodash/last';

import * as appActions from 'store/actions';
import {selectTravelOrderDefaultPresets} from 'store/reducers/registry/selectors';
import {getCurrentDispatcher} from 'store/reducers/userData/selectors';

import getDispatcherName from 'core/entities/Dispatcher/getDispatcherName';
import {transformEmailsToOptions} from 'core/entities/LocationEvents/mappers/brokerUpdate';
import {GetBrokerUpdateDataFromRequest} from 'core/entities/LocationEvents/types';
import {TRAVEL_ORDER_TRUCK_STATES} from 'core/entities/TravelOrder/constants';
import {
    isTravelOrderHasCarrier,
    isTravelOrderHasGlobalCarrier,
} from 'core/entities/TravelOrder/modules/travelOrderCarrier';
import {getTravelOrderEventTimer} from 'core/entities/TravelOrder/modules/travelOrderEventTimer';
import {getTravelOrderLastLocationEvent} from 'core/entities/TravelOrder/modules/travelOrderLocationEvents';
import TravelOrder from 'core/entities/TravelOrder/types';
import locationEventApiGateway from 'core/gateways/LocationEventApiGateway';

import {getListEmails} from 'components/common/LocationEvents/modals/BrokerUpdateSendModal/utils';

import {transformDistance} from 'utils/distance';

type UseTimerSettings = (params: {travelOrder: TravelOrder; onClose: () => void}) => any;

const useTimerSettings: UseTimerSettings = ({travelOrder, onClose}) => {
    const [brokerUpdate, setBrokerUpdate] = useState<GetBrokerUpdateDataFromRequest | null>(null);
    const defaults = useSelector(selectTravelOrderDefaultPresets);
    const dispatcher = useSelector(getCurrentDispatcher);
    const dispatch = useDispatch();

    const lastLocationEvent = getTravelOrderLastLocationEvent(travelOrder);

    const hasCarrier = isTravelOrderHasCarrier(travelOrder);
    const isGlobalCarrier = isTravelOrderHasGlobalCarrier(travelOrder);

    const carrierCanCreateAutoCheckCall = !hasCarrier || isGlobalCarrier;

    const canCreateAutoCheckCall =
        carrierCanCreateAutoCheckCall &&
        head(travelOrder.toStops)?.status === TRAVEL_ORDER_TRUCK_STATES.COMPLETED &&
        last(travelOrder.toStops)?.status === TRAVEL_ORDER_TRUCK_STATES.PLANNED;

    const fetchBrokerUpdate = async (): Promise<void> => {
        try {
            dispatch(appActions.showLoader());

            const data = await locationEventApiGateway.getBrokerUpdate(lastLocationEvent?.id || '', travelOrder.number);

            setBrokerUpdate(data);
        } catch (err) {
            onClose();
            dispatch(appActions.handleError(err));
        } finally {
            dispatch(appActions.hideLoader());
        }
    };

    const createInitFormValues = () => {
        const timer = getTravelOrderEventTimer(travelOrder);
        const delivery = timer?.delivery?.period ?? defaults.toDeliveryUpTimer;
        const pickUp = timer?.pickUp?.period ?? defaults.toPickUpTimer;

        if (!canCreateAutoCheckCall) {
            return {
                pickUp,
                delivery,
            };
        }

        if (!brokerUpdate) {
            return null;
        }

        const dispatcherName = getDispatcherName(dispatcher);
        const message = timer?.autoCheckCallSettings?.emailText || defaults.brokerUpdateText.join('\n');

        const averageSpeedFrom = transformDistance(
            timer?.autoCheckCallSettings?.averageSpeedFrom || defaults.averageSpeedFrom,
        ).fromMiles;

        const averageSpeedTo = transformDistance(
            timer?.autoCheckCallSettings?.averageSpeedTo || defaults.averageSpeedTo,
        ).fromMiles;

        return {
            pickUp,
            delivery,
            averageSpeedFrom,
            averageSpeedTo,
            autoCheckCallEnabled: Boolean(timer?.autoCheckCallSettings?.isEnabled),
            marginForDelay: timer?.autoCheckCallSettings?.etaMarginForDelay || defaults.etaMarginForDelay,
            emailSenderId: timer?.autoCheckCallSettings?.emailSenderId || null,
            emailsTo: transformEmailsToOptions(timer?.autoCheckCallSettings?.emailsTo || brokerUpdate.email.to),
            subject: timer?.autoCheckCallSettings?.emailSubject || brokerUpdate.email.subject,
            message: message.replace('[Dispatcher Name]', dispatcherName),
            updateType: defaults.brokerUpdateType.label,
        };
    };

    useEffect(() => {
        if (canCreateAutoCheckCall) {
            fetchBrokerUpdate();
        }
    }, []);

    return {
        initialFormValues: createInitFormValues(),
        brokerEmailsOptions: getListEmails(brokerUpdate?.email.to),
        emailFrom: brokerUpdate?.email.from ?? '',
        canCreateAutoCheckCall,
        distanceDiffer: defaults.differMiles,
    };
};

export default useTimerSettings;
