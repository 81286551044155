import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import toNumber from 'lodash/toNumber';

import {TRUCK_STATUS_WILL_BE_AVAILABLE_AT} from 'core/entities/Truck/constants/truckStatuses';
import {isTruckStatusAvailable} from 'core/entities/Truck/statuses';

import {UpdateStatusModalFormValues} from 'pages/Trucks/types/formTypes';

import {compareDateTime, convertDate, getCurrentDate} from 'utils/dateTime';

const transformDateTimeFormData = (params: {formData: Partial<UpdateStatusModalFormValues>}) => {
    const {formData} = params;

    const dateTime = `${formData.truckDateTime?.date} ${formData.truckDateTime?.time}`;
    const timeZone = formData.truckDateTime?.timeZone || '';

    const chosenDateTimeInUTC = convertDate(dateTime, {timeZoneFrom: timeZone, timeZoneTo: 'utc'});
    const currentDate = getCurrentDate({timeZone: 'utc'});

    const {startLessThanEnd} = compareDateTime({
        startDateTime: currentDate.originalDateTime,
        endDateTime: chosenDateTimeInUTC.defaultDateTime,
    });

    return {chosenDateTimeInUTC, isFutureDateTime: startLessThanEnd};
};

const transformTruckStatusToRequestBody = (params: {formData: Partial<UpdateStatusModalFormValues>}) => {
    const {formData} = params;

    const isTruckAvailable = isTruckStatusAvailable(formData?.status?.value || '');
    const {isFutureDateTime} = transformDateTimeFormData({formData});

    const truckStatus =
        isTruckAvailable && isFutureDateTime ? TRUCK_STATUS_WILL_BE_AVAILABLE_AT : formData?.status?.value;

    return {truckStatus};
};

export const transformFormDataToRequestBody = (params: {formData: Partial<UpdateStatusModalFormValues>}) => {
    const {formData} = params;

    const {chosenDateTimeInUTC} = transformDateTimeFormData({formData});
    const {truckStatus} = transformTruckStatusToRequestBody({formData});

    const payload = {
        availabilityDate: chosenDateTimeInUTC.defaultDateTime,
        lastCityLine: {
            country: formData?.location?.country || null,
            state: formData?.location?.state || null,
            city: formData?.location?.city || null,
            zip: formData?.location?.zip || null,
        },
        lastCoordinates: {
            longitude: formData?.location?.longitude ? toNumber(formData?.location?.longitude) : null,
            latitude: formData?.location?.latitude ? toNumber(formData?.location?.latitude) : null,
        },
        timeZone: formData?.truckDateTime?.timeZone || null,
        preferredLoads: formData?.preferredLoads?.value || null,
        status: truckStatus || null,
    };

    return omitBy(payload, (value) => isNull(value) || isUndefined(value));
};
