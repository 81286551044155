import {isTruckStatusWillBeAvailable} from 'core/entities/Truck/statuses';

import {UpdateStatusModalFormValues} from 'pages/Trucks/types/formTypes';

import {compareDateTime, convertDate, getCurrentDate} from 'utils/dateTime';
import * as errorMessages from 'utils/validation/errorMessages';
import * as regexps from 'utils/validation/regexps';

function validateTime(time: string): boolean {
    return /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(time);
}

const isPastDateTime = (params: {truckDateTime: UpdateStatusModalFormValues['truckDateTime']}) => {
    const {truckDateTime} = params;

    const dateTime = `${truckDateTime?.date} ${truckDateTime?.time}`;
    const timeZone = truckDateTime?.timeZone || '';

    const chosenDateTimeInUTC = convertDate(dateTime, {timeZoneFrom: timeZone, timeZoneTo: 'utc'});
    const currentDate = getCurrentDate({timeZone: 'utc'});

    const isPastDate = compareDateTime({
        startDateTime: currentDate.originalDate,
        endDateTime: chosenDateTimeInUTC.defaultDateInServerFormat,
    });
    const isPastTime = compareDateTime({
        startDateTime: currentDate.originalDateTime,
        endDateTime: chosenDateTimeInUTC.defaultDateTime,
    });

    return {
        isPastDate: isPastDate.startGreaterThanEnd,
        isPastTime: isPastTime.startGreaterThanOrEqualEnd,
    };
};

const validate = (values: UpdateStatusModalFormValues) => {
    const {status, location, truckDateTime} = values;

    const {date, time} = truckDateTime;

    const errors: any = {truckDateTime: {}, location: {}};

    const {isPastDate, isPastTime} = isPastDateTime({truckDateTime});

    if (!date || !regexps.DATE.test(date)) {
        errors.truckDateTime.date = errorMessages.REQUIRED;
    }

    if (isTruckStatusWillBeAvailable(status?.value) && isPastDate) {
        errors.truckDateTime.date = 'Choose future date.';
    }

    if (isTruckStatusWillBeAvailable(status?.value) && isPastTime && !isPastDate) {
        errors.truckDateTime.time = 'Choose future time.';
    }

    if (!time || !validateTime(time)) {
        errors.truckDateTime.time = errorMessages.REQUIRED;
    }

    if (!location?.cityLine) {
        errors.location.cityLine = errorMessages.REQUIRED;
    }

    if (location?.cityLine && !regexps.CITY_LINE_WITH_BRACKETS.test(location?.cityLine)) {
        errors.location.cityLine = errorMessages.INVALID_CITY_LINE_FORMAT;
    }

    return errors;
};

export default validate;
