import React, {useState} from 'react';
import classNames from 'classnames';
import {Field} from 'redux-form';

import {getName} from 'components/common/LocationEvents/modals/CheckCallModal/utils';
import UnderlineButton from 'components/ui/Buttons/UnderlineButton';
import TextArea from 'components/ui/Form/TextArea';

import useChangeReduxFormValue from 'hooks/reduxForm/useChangeReduxFormValue';
import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';

import styles from './styles.module.scss';

const NoteInfo: React.FC<{form: string}> = ({form}) => {
    const fieldName = getName('note');
    const noteValues = useGetReduxFormValue(form, fieldName);
    const changeField = useChangeReduxFormValue(form);
    const [isDisplayAddNoteField, setIsDisplayAddNoteField] = useState(Boolean(noteValues));

    const toggleShowNoteField = () => {
        if (isDisplayAddNoteField) {
            changeField(fieldName, null);
        }
        setIsDisplayAddNoteField(!isDisplayAddNoteField);
    };

    const underlineButtonText = !isDisplayAddNoteField ? 'add note' : 'delete note';

    return (
        <div className={styles.wrap}>
            <div className={classNames({[styles.button]: isDisplayAddNoteField})}>
                <UnderlineButton
                    text={underlineButtonText}
                    isNeedButtonIcon={!isDisplayAddNoteField}
                    onClick={toggleShowNoteField}
                />
            </div>

            {isDisplayAddNoteField ? (
                <Field
                    name={fieldName}
                    type="text"
                    maxLength="500"
                    component={TextArea}
                    rows="2"
                    label="Note:"
                    placeholder="enter a note here"
                />
            ) : null}
        </div>
    );
};

export default NoteInfo;
