import CharacterCount from '@tiptap/extension-character-count';
import Placeholder from '@tiptap/extension-placeholder';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import {Extension} from '@tiptap/react';

export const CustomKeyboardExtension = Extension.create({
    addKeyboardShortcuts() {
        return {
            'Shift-Enter': ({editor}) => {
                editor.commands.setHardBreak();
                editor.commands.scrollIntoView();
                return true;
            },
            'Ctrl-Enter': ({editor}) => {
                editor.commands.setHardBreak();
                editor.commands.scrollIntoView();
                return true;
            },
            Enter: () => true,
        };
    },
});

export const extensions = [
    StarterKit.configure({
        horizontalRule: false,
        orderedList: false,
        blockquote: false,
        bulletList: false,
        codeBlock: false,
        listItem: false,
        heading: false,
        italic: false,
        strike: false,
        bold: false,
        code: false,
    }),
    Placeholder.configure({placeholder: 'type your message here'}),
    Link.configure({HTMLAttributes: {class: 'message-link'}}),
    CharacterCount.configure({limit: 1500}),
    CustomKeyboardExtension,
];
