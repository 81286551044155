import React from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import classNames from 'classnames';
import {Field} from 'redux-form';

import {transformOptionsToArrayEmails} from 'core/entities/LocationEvents/mappers/brokerUpdate';
import {SENDER_TYPE_UPDATE} from 'core/entities/Sender/constants';

import SendersSelectByType from 'components/common/Sender/SendersSelectByType';
import Button from 'components/ui/Buttons/Button';
import MultiCreatableEmailsSelect from 'components/ui/Form/MultiCreatableEmailsSelect';
import RequiredLabel from 'components/ui/Form/RequiredLabel';
import TextArea from 'components/ui/Form/TextArea';
import TextInput from 'components/ui/Form/TextInput';
import TextInputBlockable from 'components/ui/Form/TextInputBlockable';

import {getName} from 'pages/TravelOrders/components/common/modals/TimerSettingsModal/components/Form/utils';

import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';

import styles from '../../styles.module.scss';

type OwnProps = {
    form: string;
    brokerEmailsOptions: Array<{label: string; value: string}>;
    emailFrom: string;
};

const CheckCallSendToSection: React.FC<OwnProps> = (props) => {
    const {form, brokerEmailsOptions, emailFrom} = props;

    const emailsTo = useGetReduxFormValue(form, getName('emailsTo'));
    const emailsTextForCopy = transformOptionsToArrayEmails(emailsTo || []).join(',');

    return (
        <div className={styles.sendTo}>
            <h4 className={classNames(styles.sectionTitle, 'ml20')}>
                <i className="fa fa-paper-plane" aria-hidden="true" />
                <span className="ml10">CHECK CALL SEND TO:</span>
            </h4>
            <div className={styles.fieldsWrapper}>
                <div className={styles.emailFrom}>
                    <Field
                        name={getName('emailSenderId')}
                        placeholder="type one or more emails"
                        component={SendersSelectByType}
                        senderType={SENDER_TYPE_UPDATE}
                        emailFrom={emailFrom}
                        label="From"
                    />
                </div>

                <div className={styles.emailsTo}>
                    <MultiCreatableEmailsSelect
                        name={getName('emailsTo')}
                        label={RequiredLabel('TO')}
                        options={brokerEmailsOptions}
                        className={styles.emailsSelect}
                        placeholder="type one or more emails"
                    />
                    <CopyToClipboard text={emailsTextForCopy}>
                        <Button colorTheme="grey" buttonSize="icon" buttonIcon="files-o" />
                    </CopyToClipboard>
                </div>

                <Field name={getName('subject')} type="text" component={TextInputBlockable} label="Subject" disabled />

                <Field type="text" name={getName('updateType')} component={TextInput} label="update Type" disabled />

                <Field
                    type="text"
                    maxLength="750"
                    rows="9"
                    label="Message"
                    name={getName('message')}
                    className={styles.scroll}
                    component={TextArea}
                    disabled
                />
            </div>
        </div>
    );
};

export default CheckCallSendToSection;
