import isEmpty from 'lodash/isEmpty';

import {AppState} from 'store';

import {TrucksNearbySSEData} from 'core/entities/Truck/types/sse';

type ListState = AppState['trucks']['list'];

export const handleReducerByReceivedSSEData = (params: {data: TrucksNearbySSEData[]; state: ListState}): ListState => {
    const {state, data} = params;

    if (isEmpty(state.items) || isEmpty(data)) {
        return state;
    }

    const relevantData = data.filter(({truck}) => state.trucksIdsSet.has(truck.id));

    if (isEmpty(relevantData)) {
        return state;
    }

    const updatedItems = state.items.map((item) => {
        const truckToUpdateData = relevantData.find(({truck}) => item.id === truck.id);

        if (truckToUpdateData) {
            const {truck: correspondingTruck} = truckToUpdateData;

            return {
                ...item,
                status: correspondingTruck.status,
                last_city_line: correspondingTruck.last_city_line,
                status_city_line: correspondingTruck.status_city_line,
                status_date: correspondingTruck.status_date,
                updated_at: correspondingTruck.updated_at,
                availabilityData: correspondingTruck.availabilityData,
                reserve: correspondingTruck.reserve,
            };
        }

        return item;
    });

    return {
        ...state,
        items: updatedItems,
    };
};
