import * as appActions from 'store/actions';
import {getCurrentSortBy, getCurrentSortFrom} from 'store/reducers/sort/selectors';

import {FactoringCompany} from 'core/entities/FactoringCompany/types';
import * as requests from 'core/gateways/FactoringCompaniesApiGateway/requests';

import * as actionTypes from 'pages/FactoringCompanies/redux/actionTypes';
import * as selectors from 'pages/FactoringCompanies/redux/selectors';
import {FactoringCompaniesReducerSearchParams} from 'pages/FactoringCompanies/types/common';
import {ListFiltersFormValues} from 'pages/FactoringCompanies/types/formTypes';

import parsePaginationHeaders from 'utils/parsePaginationHeaders';

import Pagination from 'types/Pagination';

import {
    transformFiltersFormDataToReducer,
    transformFiltersFormDataToRequestBody,
    transformReducerSearchParamsToRequestBody,
} from '../mappers/common';

export const listActionCreators = {
    factoringCompanyReceived: (payload: {factoringCompanies: FactoringCompany[]}) =>
        ({type: actionTypes.FACTORING_COMPANIES_RECEIVED, payload} as const),
    setFactoringCompaniesPagination: (payload: {pagination: Pagination}) =>
        ({type: actionTypes.SET_FACTORING_COMPANIES_PAGINATION, payload} as const),
    setFactoringCompaniesSearchParams: (payload: {searchParams: FactoringCompaniesReducerSearchParams}) =>
        ({type: actionTypes.SET_FACTORING_COMPANIES_SEARCH_PARAMS, payload} as const),
    setFactoringCompaniesListType: (payload: {isDeleted: boolean}) =>
        ({type: actionTypes.SET_FACTORING_COMPANIES_LIST_TYPE, payload} as const),
    clearFactoringCompaniesListState: () => ({type: actionTypes.CLEAR_FACTORING_COMPANIES_LIST_STATE} as const),
};

export const getFactoringCompanies = (params: {requestBody}) => async (dispatch, getState) => {
    const {requestBody} = params;

    const state = getState();

    const currentSortFrom = getCurrentSortFrom(state, 'factoringCompanies');
    const currentSortBy = getCurrentSortBy(state, 'factoringCompanies');

    const sortBy = currentSortFrom ? {[`${currentSortBy}`]: currentSortFrom} : {};

    try {
        dispatch(appActions.showLoader());

        const {data, headers} = await requests.getFactoringCompaniesRequest({requestBody: {...requestBody, sortBy}});

        const parsedPagination = parsePaginationHeaders(headers);

        return {items: data, pagination: parsedPagination};
    } catch (error) {
        dispatch(appActions.handleError(error));

        return null;
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const getFactoringCompaniesBySorting = () => async (dispatch, getState) => {
    // this action fired upon changing sorting and this action use for fetching init data for list
    const state = getState();

    const factoringCompaniesDeletedFlag = selectors.getFactoringCompaniesDeletedFlag(state);
    const searchParams = selectors.getFactoringCompaniesSearchParams(state);
    const pagination = selectors.getFactoringCompaniesPagination(state);

    const transformedReducerSearchParamsToRequestBody = transformReducerSearchParamsToRequestBody({searchParams});

    const requestBody = {
        ...transformedReducerSearchParamsToRequestBody,
        isDeleted: factoringCompaniesDeletedFlag,
        'per-page': pagination.perPage,
        page: pagination.currentPage,
    };

    const searchResult = await dispatch(getFactoringCompanies({requestBody}));

    if (!searchResult) {
        return;
    }

    dispatch(listActionCreators.factoringCompanyReceived({factoringCompanies: searchResult.items}));
    dispatch(listActionCreators.setFactoringCompaniesPagination({pagination: searchResult.pagination}));
};

export const getFactoringCompaniesByPage = (params: {page: number}) => async (dispatch, getState) => {
    const {page} = params;

    const state = getState();

    const factoringCompaniesDeletedFlag = selectors.getFactoringCompaniesDeletedFlag(state);
    const searchParams = selectors.getFactoringCompaniesSearchParams(state);
    const pagination = selectors.getFactoringCompaniesPagination(state);

    const transformedReducerSearchParamsToRequestBody = transformReducerSearchParamsToRequestBody({searchParams});

    const requestBody = {
        ...transformedReducerSearchParamsToRequestBody,
        isDeleted: factoringCompaniesDeletedFlag,
        'per-page': pagination.perPage,
        page,
    };

    const searchResult = await dispatch(getFactoringCompanies({requestBody}));

    if (!searchResult) {
        return;
    }

    dispatch(listActionCreators.factoringCompanyReceived({factoringCompanies: searchResult.items}));
    dispatch(listActionCreators.setFactoringCompaniesPagination({pagination: searchResult.pagination}));
};

export const getFactoringCompaniesByPerPage = (params: {perPage: number}) => async (dispatch, getState) => {
    const {perPage} = params;

    const state = getState();

    const factoringCompaniesDeletedFlag = selectors.getFactoringCompaniesDeletedFlag(state);
    const searchParams = selectors.getFactoringCompaniesSearchParams(state);

    const transformedReducerSearchParamsToRequestBody = transformReducerSearchParamsToRequestBody({searchParams});

    const requestBody = {
        ...transformedReducerSearchParamsToRequestBody,
        isDeleted: factoringCompaniesDeletedFlag,
        'per-page': perPage,
        page: 1,
    };

    const searchResult = await dispatch(getFactoringCompanies({requestBody}));

    if (!searchResult) {
        return;
    }

    dispatch(listActionCreators.factoringCompanyReceived({factoringCompanies: searchResult.items}));
    dispatch(listActionCreators.setFactoringCompaniesPagination({pagination: searchResult.pagination}));
};

export const getFactoringCompaniesByTabType = (params: {tabType: string}) => async (dispatch, getState) => {
    const {tabType} = params;

    const state = getState();

    const searchParams = selectors.getFactoringCompaniesSearchParams(state);
    const pagination = selectors.getFactoringCompaniesPagination(state);

    const transformedReducerSearchParamsToRequestBody = transformReducerSearchParamsToRequestBody({searchParams});

    const requestBody = {
        ...transformedReducerSearchParamsToRequestBody,
        isDeleted: Boolean(tabType === 'archived'),
        'per-page': pagination.perPage,
        page: 1,
    };

    const searchResult = await dispatch(getFactoringCompanies({requestBody}));

    if (!searchResult) {
        return;
    }

    dispatch(listActionCreators.setFactoringCompaniesListType({isDeleted: Boolean(tabType === 'archived')}));
    dispatch(listActionCreators.factoringCompanyReceived({factoringCompanies: searchResult.items}));
    dispatch(listActionCreators.setFactoringCompaniesPagination({pagination: searchResult.pagination}));
};

export const getFactoringCompaniesByFilters = (params: {filters: Partial<ListFiltersFormValues>}) => async (
    dispatch,
    getState,
) => {
    const {filters} = params;

    const state = getState();

    const factoringCompaniesDeletedFlag = selectors.getFactoringCompaniesDeletedFlag(state);
    const pagination = selectors.getFactoringCompaniesPagination(state);

    const transformedFiltersFormDataToRequestBody = transformFiltersFormDataToRequestBody({filters});

    const requestBody = {
        ...transformedFiltersFormDataToRequestBody,
        isDeleted: factoringCompaniesDeletedFlag,
        'per-page': pagination.perPage,
        page: 1,
    };

    const searchResult = await dispatch(getFactoringCompanies({requestBody}));

    if (!searchResult) {
        return;
    }

    const transformedFiltersFormDataToReducer = transformFiltersFormDataToReducer({filters});

    dispatch(listActionCreators.setFactoringCompaniesSearchParams({searchParams: transformedFiltersFormDataToReducer}));
    dispatch(listActionCreators.factoringCompanyReceived({factoringCompanies: searchResult.items}));
    dispatch(listActionCreators.setFactoringCompaniesPagination({pagination: searchResult.pagination}));
};

export const clearFiltersForm = () => async (dispatch, getState) => {
    const state = getState();

    const factoringCompaniesDeletedFlag = selectors.getFactoringCompaniesDeletedFlag(state);
    const pagination = selectors.getFactoringCompaniesPagination(state);

    const requestBody = {
        isDeleted: factoringCompaniesDeletedFlag,
        'per-page': pagination.perPage,
        page: 1,
    };

    const searchResult = await dispatch(getFactoringCompanies({requestBody}));

    if (!searchResult) {
        return;
    }

    dispatch(listActionCreators.factoringCompanyReceived({factoringCompanies: searchResult.items}));
    dispatch(listActionCreators.setFactoringCompaniesPagination({pagination: searchResult.pagination}));
    dispatch(listActionCreators.setFactoringCompaniesSearchParams({searchParams: {}}));
};
