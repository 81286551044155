import React, {useEffect} from 'react';
import {Modal} from 'react-bootstrap';

import composeFacilityCityLine from 'core/entities/Facility/composeFacilityCityLine';
import {getLoadNumber} from 'core/entities/Load/modules/loadNumber';
import {getLoadFirstPickup, getLoadLastDelivery} from 'core/entities/Load/modules/loadStop';
import Load from 'core/entities/Load/types';

import Button from 'components/ui/Buttons/Button';
import {LoadLink} from 'components/ui/Links';

import {ATTACH_LOAD_TABS} from 'pages/Loads/constants/loadConstants';
import {CarriersSearchFormValues, TrucksSearchFormValues} from 'pages/Loads/types/formTypes';

import AttachTabs from './components/AttachTabs';
import CarriersSearchForm from './components/CarriersSearchForm';
import TrucksSearchForm from './components/TrucksSearchForm';
import useAttachActions from './hooks/useAttachActions';
import useAttachState from './hooks/useAttachState';

const CARRIERS_SEARCH_FORM_NAME = 'load-attach-carriers-search-form';
const TRUCKS_SEARCH_FORM_NAME = 'load-attach-trucks-search-form';

type OwnProps = {
    onClose: () => void;
    data: {load: Load};
};

const AttachModal: React.FC<OwnProps> = (props) => {
    const {
        data: {load},
        onClose,
    } = props;

    const {activeTab, isAvailableMoreTrucks} = useAttachState();
    const {
        getMoreTrucksForAttaching,
        setSearchCarriersParams,
        fetchCarriers,
        searchTrucks,
        clearState,
    } = useAttachActions();

    useEffect(() => {
        fetchCarriers();

        return () => {
            clearState();
        };
    }, []);

    const submitTrucksSearch = (formData: Partial<TrucksSearchFormValues>) => {
        searchTrucks({formData});
    };

    const submitCarriersSearch = (formData: Partial<CarriersSearchFormValues>) => {
        setSearchCarriersParams({formData});
    };

    const handleGetMoreTrucks = () => {
        getMoreTrucksForAttaching();
    };

    const firstPickup = getLoadFirstPickup(load);
    const lastDelivery = getLoadLastDelivery(load);

    const firstPickupFacilityCityLine = composeFacilityCityLine(firstPickup?.facility);
    const lastDeliveryFacilityCityLine = composeFacilityCityLine(lastDelivery?.facility);

    const loadNumber = getLoadNumber(load);

    const displayFacilityStopsCityLines = `(${firstPickupFacilityCityLine} - ${lastDeliveryFacilityCityLine})`;

    return (
        <Modal show={true} dialogClassName="standard-form-modal" backdrop="static">
            <Modal.Header className="modal-header__top">
                <Modal.Title>
                    Attaching item to the{' '}
                    <LoadLink load={load} content={`Load#${loadNumber}`} className="second-link" />{' '}
                    {displayFacilityStopsCityLines}
                </Modal.Title>

                <button onClick={onClose} type="button" className="close">
                    <i className="fa fa-times" aria-hidden="true" />
                </button>
            </Modal.Header>

            <Modal.Body>
                {activeTab === ATTACH_LOAD_TABS.TRUCKS ? (
                    <TrucksSearchForm form={TRUCKS_SEARCH_FORM_NAME} onSubmit={submitTrucksSearch} />
                ) : (
                    <CarriersSearchForm form={CARRIERS_SEARCH_FORM_NAME} onSubmit={submitCarriersSearch} />
                )}

                <AttachTabs />

                <div className="modal-body__bottom">
                    <Button onClick={onClose}>Cancel</Button>

                    {activeTab === ATTACH_LOAD_TABS.TRUCKS ? (
                        <Button
                            disabled={!isAvailableMoreTrucks}
                            onClick={handleGetMoreTrucks}
                            colorTheme="blue"
                            type="submit"
                        >
                            Load More
                        </Button>
                    ) : null}
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default AttachModal;
