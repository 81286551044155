import {Driver} from '../types/group';

export const getDriverTmsEntityID = (driver: Driver, serverID: string): number | null => {
    return driver?.additionalData?.find((item) => item.serverID === serverID)?.tmsEntityID || null;
};

export const getDriverLanguage = (driver: Driver, serverID: string): string => {
    return driver?.additionalData?.find((item) => item?.serverID === serverID)?.language || '';
};

export const getDriverAvatar = (driver: Driver, serverID: string): string | null => {
    return driver?.avatars?.find((item) => item?.serverID === serverID)?.thumb || null;
};

export const getTruckNumberFromDriver = (driver: Driver, serverID: string): string | null => {
    return driver?.trucks?.find((item) => item?.serverID === serverID)?.number || null;
};

export const getTruckFromDriver = (driver: Driver, serverID: string): Driver['trucks'][number] | null => {
    return driver?.trucks?.find((item) => item?.serverID === serverID) || null;
};
