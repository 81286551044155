import isNull from 'lodash/isNull';
import isNumber from 'lodash/isNumber';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';

import {FinishModalFormValues} from 'pages/TravelOrders/types/formTypes';

import {createDate} from 'utils/dateTime';

export const transformFinishDataToRequestBody = (finishData: FinishModalFormValues) => {
    const {truckDateTime, checkOutDateTime} = finishData;
    const {date: truckDate, time: truckTime, timeZone: truckTimeZone} = truckDateTime || {};
    const {date: checkOutDate, time: checkOutTime, timeZone: checkOutTimeZone} = checkOutDateTime;

    // transform truck's dateTime
    const truckDateTimeOBJ = createDate(`${truckDate} ${truckTime}`, {fromTimeZone: truckTimeZone, toTimeZone: 'utc'});
    const {fullOriginalDateTime: truckDateTimeInTheServerFormat, isValid: isValidTruckDateTime} = truckDateTimeOBJ;

    // transform checkOut's dateTime
    const checkOutDateTimeOBJ = createDate(`${checkOutDate} ${checkOutTime}`, {
        fromTimeZone: checkOutTimeZone,
        toTimeZone: 'utc',
    });
    const {
        fullOriginalDateTime: checkOutDateTimeInTheServerFormat,
        isValid: isValidCheckOutDateTime,
    } = checkOutDateTimeOBJ;

    const truckDateTimeForRequest = {
        dateTime: truckDateTimeInTheServerFormat,
        timeZone: truckTimeZone || null,
    };

    const payloadParams = {
        truckDateTime: isValidTruckDateTime ? truckDateTimeForRequest : null,
        checkOutDateTime: {
            timeZone: checkOutTimeZone && isValidCheckOutDateTime ? checkOutTimeZone : null,
            dateTime: isValidCheckOutDateTime ? checkOutDateTimeInTheServerFormat : null,
        },
        longitude: isNumber(finishData?.longitude) ? finishData.longitude : null,
        latitude: isNumber(finishData?.latitude) ? finishData.latitude : null,
        truckStatus: finishData?.truckStatus?.value || null,
        sendSurvey: finishData?.sendSurvey || null,
        cityLine: finishData?.cityLine || null,
    };

    return omitBy(payloadParams, (value) => isNull(value) || isUndefined(value));
};
