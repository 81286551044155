import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import toNumber from 'lodash/toNumber';

import {FactoringCompaniesReducerSearchParams} from 'pages/FactoringCompanies/types/common';
import {ListFiltersFormValues} from 'pages/FactoringCompanies/types/formTypes';

const removeEmptyObjValues = (obj) => {
    const entries = Object.entries(obj);

    const removedNull = entries.filter(([_, value]) => value !== null);
    const removedUndefined = removedNull.filter(([_, value]) => value !== undefined);

    return Object.fromEntries(removedUndefined);
};

export const transformReducerSearchParamsToRequestBody = (params: {
    searchParams: FactoringCompaniesReducerSearchParams;
}) => {
    const {searchParams} = params;

    const searchParamsBeforeClearing = {
        id: isNumber(searchParams?.companyID) ? searchParams.companyID : null,
        country: searchParams?.country?.value || null,
        companyName: searchParams?.companyName || null,
        state: isString(searchParams?.state) ? searchParams?.state : searchParams?.state?.value || null,
        city: searchParams?.city || null,
        zip: searchParams?.zip || null,
        email: searchParams?.email || null,
        phone: searchParams?.phone || null,
    };

    return removeEmptyObjValues(searchParamsBeforeClearing);
};

export const transformFiltersFormDataToReducer = (params: {
    filters: Partial<ListFiltersFormValues>;
}): FactoringCompaniesReducerSearchParams => {
    const {filters} = params;

    const clearedLocation = removeEmptyObjValues({
        country: filters?.country || undefined,
        state: filters?.state || undefined,
        city: filters?.city || undefined,
        zip: filters?.zip || undefined,
    });

    const searchParams: FactoringCompaniesReducerSearchParams = {
        ...(clearedLocation || {}),
        companyID: filters.companyID ? toNumber(filters.companyID) : undefined,
        companyName: filters.companyName || undefined,
        email: filters.email || undefined,
        phone: filters.phone || undefined,
    };

    return removeEmptyObjValues(searchParams);
};

export const transformFiltersFormDataToRequestBody = (params: {filters: Partial<ListFiltersFormValues>}) => {
    const {filters} = params;

    const searchParamsBeforeClearing = {
        id: filters?.companyID ? toNumber(filters?.companyID) : null,
        country: filters?.country?.value || null,
        companyName: filters?.companyName || null,
        state: isString(filters?.state) ? filters?.state : filters?.state?.value || null,
        city: filters?.city || null,
        zip: filters?.zip || null,
        email: filters?.email || null,
        phone: filters?.phone || null,
    };

    return removeEmptyObjValues(searchParamsBeforeClearing);
};
