import isNumber from 'lodash/isNumber';

import {createDate} from 'utils/dateTime';

import * as formTypes from 'pages/TravelOrders/types/formTypes';

type CancelTORequest = {
    cityLine: string | null;
    dateTime: string | null;
    latitude: number | null;
    longitude: number | null;
    timeZone: string | null;
};

export const transformCancelPlannedTO = (
    formData: formTypes.CancelNotPlannedTOWithPlannedTOFormValues,
): CancelTORequest & {truckStatus: null} => {
    const {date, time, timeZone, cityLine, latitude, longitude} = formData;

    const {fullOriginalDateTime: dateTime, isValid} = createDate(`${date} ${time}`, {
        fromTimeZone: timeZone,
        toTimeZone: 'utc',
    });

    return {
        longitude: isNumber(longitude) ? longitude : null,
        latitude: isNumber(latitude) ? latitude : null,
        timeZone: isValid && timeZone ? timeZone : null,
        dateTime: isValid ? dateTime : null,
        cityLine: cityLine || null,
        truckStatus: null,
    };
};

export const transformCancelNotPlannedTO = (
    formData: formTypes.CancelNotPlannedTOWithoutPlannedTOFormValues,
): CancelTORequest & {truckStatus: string} => {
    const {date, time, timeZone, cityLine, latitude, longitude, truckStatus} = formData;

    const {fullOriginalDateTime: dateTime, isValid} = createDate(`${date} ${time}`, {
        fromTimeZone: timeZone,
        toTimeZone: 'utc',
    });

    return {
        longitude: isNumber(longitude) ? longitude : null,
        latitude: isNumber(latitude) ? latitude : null,
        timeZone: isValid && timeZone ? timeZone : null,
        dateTime: isValid ? dateTime : null,
        truckStatus: truckStatus.value,
        cityLine: cityLine || null,
    };
};
