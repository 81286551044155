import {useDispatch} from 'react-redux';

import * as actions from 'widgets/Chat/redux/actions';

import {chatWSConnect} from '../redux/actions/wsActions';
import {DriverSearchFormValues, MessageFormValues} from '../types/form';

type UseChatActions = {
    openDriversGroupFromTripMonitorByTruckNumber: (params: {truckNumber: string; endSearching: () => void}) => void;
    markMessagesAsReadByEveryDispatcher: (params: {groupID: number | null; onFinish?: () => void}) => void;
    sendMessageByDispatcherToDriversGroup: (params: {message: Partial<MessageFormValues>}) => void;
    getDriversGroupAllMessages: (params: {cognitoUserID: string; onFinish?: () => void}) => void;
    getDriversGroupInitLatestMessages: (params: {cognitoUserID: string}) => void;
    sendAttachmentsByUpload: (params: {fileList: FileList | null}) => void;
    getDriversGroupMoreMessages: (params: {cognitoUserID: string; setIsLoading?: (isLoading: boolean) => void}) => void;
    getTruckForDriversGroup: (params: {cognitoUserID: string}) => void;
    leaveDriversGroup: (params: {cognitoUserID: string}) => void;
    openDriversGroup: (params: {cognitoUserID: string}) => void;
    allMessagesAreLoaded: (isLoaded: boolean) => void;
    searchDriverByTruckNumber: (params: {
        formValues: Partial<DriverSearchFormValues>;
        endSearching: () => void;
    }) => void;
    loadMoreGroups: ({hideLoader}) => void;
    clearMessageAttachments: () => void;
    clearSearchedDrivers: () => void;
    receiveDriversGroups: () => void;
    initializeChat: () => void;
    chatWSConnect: () => void;
    toggleChat: () => void;
};

const useChatActions = (): UseChatActions => {
    const dispatch = useDispatch();

    return {
        markMessagesAsReadByEveryDispatcher: (params) => dispatch(actions.markMessagesAsReadByEveryDispatcher(params)),
        getDriversGroupInitLatestMessages: (params) => dispatch(actions.getDriversGroupInitLatestMessages(params)),
        allMessagesAreLoaded: (param) => dispatch(actions.chatActionCreators.allMessagesAreLoaded(param)),
        getDriversGroupMoreMessages: (params) => dispatch(actions.getDriversGroupMoreMessages(params)),
        clearMessageAttachments: () => dispatch(actions.chatActionCreators.clearMessageAttachments()),
        getDriversGroupAllMessages: (params) => dispatch(actions.getDriversGroupAllMessages(params)),
        searchDriverByTruckNumber: (params) => dispatch(actions.searchDriverByTruckNumber(params)),
        getTruckForDriversGroup: (params) => dispatch(actions.getTruckForDriversGroup(params)),
        sendAttachmentsByUpload: (params) => dispatch(actions.sendAttachmentsByUpload(params)),
        loadMoreGroups: ({hideLoader}) => dispatch(actions.loadMoreGroups({hideLoader})),
        leaveDriversGroup: (params) => dispatch(actions.leaveDriversGroup(params)),
        openDriversGroupFromTripMonitorByTruckNumber: (params) =>
            dispatch(actions.openDriversGroupFromTripMonitorByTruckNumber(params)),
        openDriversGroup: (params) => dispatch(actions.openDriversGroup(params)),
        clearSearchedDrivers: () => dispatch(actions.clearSearchedDrivers()),
        toggleChat: () => dispatch(actions.chatActionCreators.toggleChat()),
        sendMessageByDispatcherToDriversGroup: (params) =>
            dispatch(actions.sendMessageByDispatcherToDriversGroup(params)),
        receiveDriversGroups: () => dispatch(actions.receiveDriversGroups()),
        initializeChat: () => dispatch(actions.initializeChat()),
        chatWSConnect: () => dispatch(chatWSConnect()),
    };
};

export default useChatActions;
