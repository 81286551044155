import React from 'react';

import styles from './styles.module.scss';

type OwnProps = {
    isLoading: boolean;
};

const Loader: React.FC<OwnProps> = (props) => {
    const {isLoading} = props;

    if (!isLoading) {
        return null;
    }

    return (
        <div className={styles.spinnerWrapper}>
            <div className={styles.icon}>
                <i className="fa fa-spinner fa-spin" aria-hidden="true" />
            </div>
            Loading...
        </div>
    );
};

export default Loader;
