import {isArray} from 'lodash';
import isEmpty from 'lodash/isEmpty';
import {ThunkAction} from 'redux-thunk';

import {AppState} from 'store';

import {DriverOffer, DriverReceiverWithTruck} from 'core/entities/Quote/types';
import Truck from 'core/entities/Truck/types';

import {getData, updateItem} from 'utils/normalizer';

import * as actionCreators from '../actionCreators';
import {findDriverOfferByTruckID, findDriverReceiverByTruckID} from '../reducers/utils';
import {getNormalizedQuoteOffers, getQuoteDriversReceivers} from '../selectors';
import {getAllItems, getCurrentItems} from '../selectors/quoteReceivers';

export function changePaginationPage(page) {
    return function (dispatch) {
        dispatch(actionCreators.changedPaginationPage(page));
    };
}

export function changePaginationCount(count) {
    return function (dispatch) {
        dispatch(actionCreators.changedPaginationCount(count));
    };
}

export const handleSSEData = (payload: {data}): ThunkAction<void, AppState, unknown, any> => (dispatch, getState) => {
    const {data: trucks = []} = payload || {};

    if (!isArray(trucks) || (isArray(trucks) && isEmpty(trucks))) {
        return;
    }

    const filteredTrucks = trucks.map((data) => (!isEmpty(data?.truck) ? data.truck : null)).filter(Boolean);

    if (isEmpty(filteredTrucks)) {
        return;
    }

    const state = getState();

    let updatedOffers = {...getNormalizedQuoteOffers(state)};
    let updatedQuoteReceivers = {...getQuoteDriversReceivers(state)};

    filteredTrucks.forEach((truck) => {
        const quoteDriverOffer = findDriverOfferByTruckID(state.loadBoard.list, truck.id);
        const quoteDriverReceiver = findDriverReceiverByTruckID(state.loadBoard.list, truck.id);

        if (!quoteDriverOffer || !quoteDriverReceiver) {
            return;
        }

        const offerWithReservedTruck = {
            ...quoteDriverOffer,
            truck: {...quoteDriverOffer.truck, reserve: truck.reserve},
        };

        const receiverWithReservedTruck = {
            ...quoteDriverReceiver,
            truck: {...(quoteDriverReceiver.truck as Truck), reserve: truck.reserve},
        };

        updatedOffers = updateItem<DriverOffer>(updatedOffers, offerWithReservedTruck);
        updatedQuoteReceivers = updateItem<DriverReceiverWithTruck>(updatedQuoteReceivers, receiverWithReservedTruck);
    });

    dispatch(actionCreators.sseDriverOffersUpdated({offers: updatedOffers}));
    dispatch(actionCreators.sseQuotesReceiversUpdated({quoteReceivers: updatedQuoteReceivers}));

    const normalizedTrucks = getData(filteredTrucks);
    const quoteReceiversModalAllItems = getAllItems(state);
    const quoteReceiversModalCurrentItems = getCurrentItems(state);

    const updateQuoteReceiversTrucks = (receivers: DriverReceiverWithTruck[]) =>
        receivers.map((item) => {
            const {truck} = item;

            if (!truck?.id) {
                return item;
            }

            const updatedTruck = normalizedTrucks?.byId?.[truck.id];

            if (!updatedTruck) {
                return item;
            }

            return {
                ...item,
                ...(isEmpty(item?.truck) ? {} : {truck: {...item.truck, reserve: updatedTruck.reserve}}),
            };
        });

    const updatedQuoteReceiversModalAllItems = updateQuoteReceiversTrucks(quoteReceiversModalAllItems);
    const updatedQuoteReceiversModalCurrentItems = updateQuoteReceiversTrucks(quoteReceiversModalCurrentItems);

    dispatch(
        actionCreators.quoteReceiverModalItemsUpdated({
            allItems: updatedQuoteReceiversModalAllItems,
            currentItems: updatedQuoteReceiversModalCurrentItems,
        }),
    );
};
