import React from 'react';

import DriverAvatar from 'widgets/Chat/components/DriverAvatar';
import useChatActions from 'widgets/Chat/hooks/useChatActions';
import useChatState from 'widgets/Chat/hooks/useChatState';
import {DriversGroup as DriversGroupType} from 'widgets/Chat/types/group';
import {getTruckFromDriver} from 'widgets/Chat/utils';

import LastMessage from './components/LastMessage';
import UnreadInfo from './components/UnreadInfo';

import styles from './styles.module.scss';

type OwnProps = {
    driversGroup: DriversGroupType;
};

const DriversGroup: React.FC<OwnProps> = (props) => {
    const {driversGroup} = props;

    const {openDriversGroup} = useChatActions();
    const {serverID} = useChatState();

    const [driver] = driversGroup.drivers || [];

    const driverType = driver.isOwner ? '(o/d)' : '(1dr)';

    const truck = getTruckFromDriver(driver, serverID);

    const handleEnterGroup = () => {
        openDriversGroup({cognitoUserID: driver.cognitoUserId});
    };

    return (
        <div className={styles.wrap} onClick={handleEnterGroup}>
            <div className="d-flex align-items-center">
                <DriverAvatar driver={driver} />

                <div className="ml20" />

                <div className={styles.info}>
                    <div className="font-bold">
                        {truck ? `#${truck.number}` : null}
                        {truck?.isReefer ? '-R ' : ' '}
                        {driver.name}
                    </div>

                    <LastMessage driversGroup={driversGroup} />
                </div>
            </div>

            <div className={styles.type}>
                <div>{driverType}</div>

                <UnreadInfo driversGroup={driversGroup} />
            </div>
        </div>
    );
};

export default DriversGroup;
