import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import omitBy from 'lodash/omitBy';

type CurrentSearchParams = {
    billing_city?: string;
    billing_country?: {label: string; value: string};
    billing_method?: string;
    billing_state?: string | {label: string; value: string};
    billing_zip?: string;
    id?: string;
    name?: string;
    customerType?: string;
    main_phone?: string;
};

type CurrentSearchParamsToRequest = Omit<CurrentSearchParams, 'customerType' | 'billing_country'> & {
    customerType?: string[];
    billing_country?: string;
};

export const transformSearchDataToRequest = (searchData: CurrentSearchParams): CurrentSearchParamsToRequest => {
    return omitBy(
        {
            ...searchData,
            billing_state: isString(searchData?.billing_state)
                ? searchData?.billing_state
                : searchData?.billing_state?.value,
            billing_country: searchData?.billing_country?.value,
            customerType: searchData?.customerType ? searchData.customerType.split(',') : undefined,
        },
        isNil,
    );
};
