import {useDispatch} from 'react-redux';
import {clearFields} from 'redux-form';

type UseClearReduxFormValue = (fieldName: string) => void;

const useClearReduxFormValue = (formName: string): UseClearReduxFormValue => {
    const dispatch = useDispatch();

    return (fieldName) => dispatch(clearFields(formName, false, false, fieldName));
};

export default useClearReduxFormValue;
