import compact from 'lodash/compact';
import differenceBy from 'lodash/differenceBy';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';

import {transferTravelOrderSignedAttachments} from 'core/entities/TravelOrder/modules/travelOrderAttachments/signedAttachments';
import {isTravelOrderHasCarrier} from 'core/entities/TravelOrder/modules/travelOrderCarrier';
import TravelOrder from 'core/entities/TravelOrder/types';

import {DryRunConfirmFormValues, DryRunModalFormValues} from 'pages/TravelOrders/types/formTypes';

import {createDate} from 'utils/dateTime';

import {getAttachmentsMetaData} from '../attachments';

const getTransformedAttachmentData = (params: {
    data: DryRunConfirmFormValues | DryRunModalFormValues;
    travelOrder?: TravelOrder | null;
}) => {
    const {
        data: {attachments},
        travelOrder,
    } = params;

    const storedFormFiles = (attachments || []).filter((attachment) => 'id' in attachment);
    const stopFiles = transferTravelOrderSignedAttachments(travelOrder);

    const deletedFiles = differenceBy(stopFiles, storedFormFiles, 'id');
    const deletedFileIDs = compact(deletedFiles.map((deletedFile) => deletedFile.id));

    const convertedAttachments = getAttachmentsMetaData(attachments);

    return {
        files: convertedAttachments?.length ? convertedAttachments : undefined,
        deleteFileIds: deletedFileIDs?.length && attachments?.length ? deletedFileIDs : undefined,
    };
};
export const transformDryRunDataToRequest = (params: {
    dryRunData: DryRunModalFormValues;
    travelOrder?: TravelOrder | null;
}) => {
    const {dryRunData, travelOrder} = params;

    const {cityLine, latitude, longitude, driverPayment, totalCharges, reason, dryMiles} = dryRunData;

    const transformedAttachmentData = getTransformedAttachmentData({data: dryRunData, travelOrder});

    return {
        driverPayment: isNumber(driverPayment) ? driverPayment : null,
        totalCharges: isNumber(totalCharges) ? totalCharges : null,
        reason: isString(reason?.value) ? reason?.value : null,
        longitude: isNumber(longitude) ? longitude : null,
        latitude: isNumber(latitude) ? latitude : null,
        dryMiles: isNumber(dryMiles) ? dryMiles : null,
        cityLine: cityLine || null,
        ...transformedAttachmentData,
    };
};

export const getTransformedDryRunConfirmTruckData = (params: {confirmDryRunData: DryRunConfirmFormValues}) => {
    const {confirmDryRunData} = params;

    const {cityLine, latitude, longitude, truckStatus, truckDateTime} = confirmDryRunData;

    const {date, time, timeZone} = truckDateTime || {};

    const createdDate = createDate(`${date} ${time}`, {fromTimeZone: timeZone, toTimeZone: 'utc'});
    const {fullOriginalDateTime, isValid} = createdDate;

    return {
        truckDateTime: isValid ? fullOriginalDateTime : null,
        longitude: isNumber(longitude) ? longitude : null,
        latitude: isNumber(latitude) ? latitude : null,
        truckStatus: truckStatus?.value || null,
        cityLine: cityLine || null,
    };
};

export const getTransformedCheckOutDateTime = (params: {confirmDryRunData: DryRunConfirmFormValues}) => {
    const {confirmDryRunData} = params;

    const {date, time, timeZone} = confirmDryRunData;

    const createdDate = createDate(`${date} ${time}`, {fromTimeZone: timeZone, toTimeZone: 'utc'});
    const {fullOriginalDateTime, isValid} = createdDate;

    return {
        checkOutDateTime: isValid ? fullOriginalDateTime : null,
    };
};

export const transformDryRunConfirmDataToRequest = (params: {
    confirmDryRunData: DryRunConfirmFormValues;
    travelOrder?: TravelOrder | null;
}) => {
    const {confirmDryRunData, travelOrder} = params;

    const isTOHasCarrier = isTravelOrderHasCarrier(travelOrder);

    const transformedDryRunTruckConfirmData = getTransformedDryRunConfirmTruckData({confirmDryRunData});
    const transformedCheckOutDateTime = getTransformedCheckOutDateTime({confirmDryRunData});
    const transformedAttachmentData = getTransformedAttachmentData({data: confirmDryRunData, travelOrder});

    if (isTOHasCarrier) {
        return {...transformedCheckOutDateTime, ...transformedAttachmentData};
    }

    return {...transformedDryRunTruckConfirmData, ...transformedCheckOutDateTime};
};
