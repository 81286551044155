export const languages = Object.freeze({
    EN: {full: 'EN - English', name: 'English', code: 'EN'},
    RU: {full: 'RU - Russian', name: 'Russian', code: 'RU'},
    ES: {full: 'ES - Spanish', name: 'Spanish', code: 'ES'},
    AM: {full: 'AM - Amharic', name: 'Amharic', code: 'AM'},
    AR: {full: 'AR - Arabic', name: 'Arabic', code: 'AR'},
    HR: {full: 'HR - Croatian', name: 'Croatian', code: 'HR'},
    FR: {full: 'FR - French', name: 'French', code: 'FR'},
    HI: {full: 'HI - Hindi', name: 'Hindi', code: 'HI'},
    PL: {full: 'PL - Polish', name: 'Polish', code: 'PL'},
    RO: {full: 'RO - Romanian', name: 'Romanian', code: 'RO'},
    SR: {full: 'SR - Serbian', name: 'Serbian', code: 'SR'},
    SO: {full: 'SO - Somali', name: 'Somali', code: 'SO'},
    SW: {full: 'SW - Swahili', name: 'Swahili', code: 'SW'},
    TI: {full: 'TI - Tigrinya', name: 'Tigrinya', code: 'TI'},
    TR: {full: 'TR - Turkish', name: 'Turkish', code: 'TR'},
    UR: {full: 'UR - Urdu', name: 'Urdu', code: 'UR'},
    UZ: {full: 'UZ - Uzbek', name: 'Uzbek', code: 'UZ'},
    MK: {full: 'MK - Macedonian', name: 'Macedonian', code: 'MK'},
    FA: {full: 'FA - Persian', name: 'Persian', code: 'FA'},
    YO: {full: 'YO - Yoruba', name: 'Yoruba', code: 'YO'},
    DE: {full: 'DE - German', name: 'German', code: 'DE'},
    UA: {full: 'UA - Ukrainian', name: 'Ukrainian', code: 'UA'},
    IT: {full: 'IT - Italian', name: 'Italian', code: 'IT'},
    LT: {full: 'LT - Lithuanian', name: 'Lithuanian', code: 'LT'},
    BG: {full: 'BG - Bulgarian', name: 'Bulgarian', code: 'BG'},
    CZ: {full: 'CZ - Czech', name: 'Czech', code: 'CZ'},
    SK: {full: 'SK - Slovak', name: 'Slovak', code: 'SK'},
    HU: {full: 'HU - Hungarian', name: 'Hungarian', code: 'HU'},
});

export const languagesOptions = Object.values(languages).map((item) => ({
    label: item.full,
    value: item.code.toLowerCase(),
}));
