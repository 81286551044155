import {useSelector} from 'react-redux';

import * as userSelectors from 'store/reducers/userData/selectors';

import * as modalSelectors from 'components/ui/ModalProvider/selectors';

import * as attachSelectors from 'pages/Loads/redux/selectors/attach';

type UseAttachState = {
    isAvailableMoreTrucks: ReturnType<typeof attachSelectors.checkIsAvailableMoreTrucks>;
    carriersSearchParams: ReturnType<typeof attachSelectors.getCarriersSearchParams>;
    trucksSearchParams: ReturnType<typeof attachSelectors.getTrucksSearchParams>;
    currentDispatcherID: ReturnType<typeof userSelectors.getCurrentUserID>;
    activeTab: ReturnType<typeof attachSelectors.getAttachModalActiveTab>;
    searchCarriers: ReturnType<typeof attachSelectors.getSearchCarriers>;
    loadToAttach: ReturnType<typeof modalSelectors.getCurrentModalData>;
    carriers: ReturnType<typeof attachSelectors.getCarriers>;
    trucks: ReturnType<typeof attachSelectors.getTrucks>;
};

const useAttachState = (): UseAttachState => ({
    isAvailableMoreTrucks: useSelector(attachSelectors.checkIsAvailableMoreTrucks),
    carriersSearchParams: useSelector(attachSelectors.getCarriersSearchParams),
    trucksSearchParams: useSelector(attachSelectors.getTrucksSearchParams),
    loadToAttach: useSelector(modalSelectors.getCurrentModalData).load,
    currentDispatcherID: useSelector(userSelectors.getCurrentUserID),
    activeTab: useSelector(attachSelectors.getAttachModalActiveTab),
    searchCarriers: useSelector(attachSelectors.getSearchCarriers),
    carriers: useSelector(attachSelectors.getCarriers),
    trucks: useSelector(attachSelectors.getTrucks),
});

export default useAttachState;
