import React from 'react';

import {
    getCountDriversWhoAllowedSendQuote,
    getCountDriversWhoDeclinedQuote,
    getCountDriversWhoNotAllowedSendQuote,
    getCountDriversWhoProvidedQuote,
    getCountDriversWhoReadQuote,
} from 'core/entities/Quote/modules/quoteDriverReceiver';
import {DriverReceiverWithTruck} from 'core/entities/Quote/types';

import useQuoteReceiversActions from 'pages/LoadBoard/hooks/useQuoteReceiversActions';
import useQuoteReceiversState from 'pages/LoadBoard/hooks/useQuoteReceiversState';

import CheckBox from './components/CheckBox';
import Counter from './components/Counter';

type OwnProps = {
    driversReceivers: DriverReceiverWithTruck[];
};

const Options: React.FC<OwnProps> = (props) => {
    const {driversReceivers} = props;

    const {
        toggleDriverReadQuote,
        toggleAllowedSendToDriver,
        toggleDriverDeclinedQuote,
        toggleDriverProvidedQuote,
        toggleNotAllowedSendToDriver,
    } = useQuoteReceiversActions();
    const {sentChecked, notSentChecked, declinedChecked, readChecked, providedChecked} = useQuoteReceiversState();

    const driversWhoAllowedSendQuoteCounter = getCountDriversWhoAllowedSendQuote(driversReceivers);
    const driversWhoNotAllowedSendQuoteCounter = getCountDriversWhoNotAllowedSendQuote(driversReceivers);
    const driversWhoDeclinedQuoteCounter = getCountDriversWhoDeclinedQuote(driversReceivers);
    const driversWhoReadQuoteCounter = getCountDriversWhoReadQuote(driversReceivers);
    const driversWhoProvidedQuoteCounter = getCountDriversWhoProvidedQuote(driversReceivers);

    return (
        <div className="d-flex">
            <div className="d-flex list-with-gap-10 mr-20">
                <div className="mr-10">Statuses: </div>
                <CheckBox checked={sentChecked} onSelect={toggleAllowedSendToDriver} />
                SENT
                <Counter counter={driversWhoAllowedSendQuoteCounter} />
            </div>

            <div className="d-flex list-with-gap-10">
                <CheckBox checked={notSentChecked} onSelect={toggleNotAllowedSendToDriver} />
                WASN`T SENT
                <Counter counter={driversWhoNotAllowedSendQuoteCounter} />
            </div>

            <div className="d-flex list-with-gap-10">
                <CheckBox checked={declinedChecked} onSelect={toggleDriverDeclinedQuote} />
                DECLINED
                <Counter counter={driversWhoDeclinedQuoteCounter} />
            </div>

            <div className="d-flex list-with-gap-10">
                <CheckBox checked={readChecked} onSelect={toggleDriverReadQuote} />
                OPENED
                <Counter counter={driversWhoReadQuoteCounter} />
            </div>

            <div className="d-flex list-with-gap-10">
                <CheckBox checked={providedChecked} onSelect={toggleDriverProvidedQuote} />
                PROVIDED
                <Counter counter={driversWhoProvidedQuoteCounter} />
            </div>
        </div>
    );
};

export default Options;
