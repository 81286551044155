import React from 'react';
import {Modal} from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';

import {GetBrokerUpdateDataFromRequest} from 'core/entities/LocationEvents/types';

import Form from './components/BrokerUpdateSendForm';
import PreviewEventForBroker from './components/PreviewEventForBroker';

import {clearEmptyStopAttachments, createInitFormValues} from './utils';

type OwnProps = {
    data: GetBrokerUpdateDataFromRequest;
    handlers: {
        onUpdate: (brokerUpdate) => void;
    };
    onClose: () => void;
};

const BrokerUpdateSendModal: React.FC<OwnProps> = (props) => {
    const {
        data: {
            email: {subject, to, from},
            info: {updateType},
            updateTypeMessages,
            travelOrderNumber,
            updateTypeOptions,
            truckNumber,
            attachments,
            prevUpdate,
        },
        handlers: {onUpdate},
        onClose,
    } = props;

    const showTitleWithTruck = !isEmpty(truckNumber);

    const files = clearEmptyStopAttachments(attachments);

    const initFormValues = createInitFormValues({
        travelOrderNumber,
        updateTypeOptions,
        emailsTo: to,
        updateType,
        subject,
    });

    return (
        <Modal
            dialogClassName="custom-modal standard-form-modal"
            backdrop="static"
            onHide={onClose}
            show={true}
            size="sm"
        >
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>
                        Sending Location Event to Broker:{' '}
                        {showTitleWithTruck ? <strong>Truck #{truckNumber}</strong> : <strong>{}</strong>}
                    </Modal.Title>

                    <button onClick={onClose} type="button" className="close">
                        <i className="fa fa-times" aria-hidden="true" />
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body>
                {prevUpdate && (
                    <PreviewEventForBroker
                        previouslyUpdateType={prevUpdate.eventType}
                        previouslyCityLine={prevUpdate.cityLine}
                        previouslyUpdatedAt={prevUpdate.updateTime}
                    />
                )}
                <Form
                    updateTypeMessages={updateTypeMessages}
                    updateTypeOptions={updateTypeOptions}
                    initialValues={initFormValues}
                    brokerEmailsData={to}
                    onSubmit={onUpdate}
                    onClose={onClose}
                    emailFrom={from}
                    files={files}
                />
            </Modal.Body>
        </Modal>
    );
};

export default BrokerUpdateSendModal;
