import {ThunkAction} from 'redux-thunk';

import {AppState} from 'store';
import * as appActions from 'store/actions';
import adminActions from 'store/reducers/adminSettings/actionCreators';
import {getShowDeleted} from 'store/reducers/adminSettings/selectors';
import {getCurrentSortBy, getCurrentSortFrom} from 'store/reducers/sort/selectors';

import Customer from 'core/entities/Customer/types';
import {searchCustomers as searchCustomersApiReq} from 'core/gateways/CustomerApiGateway/requests';

import * as actionListTypes from 'pages/Customers/redux/actionTypes/list';
import {transformSearchDataToRequest} from 'pages/Customers/redux/mappers';
import * as selectors from 'pages/Customers/redux/selectors';

import {ENTITY_NAME_CUSTOMERS} from 'utils/data/entityNames';
import formatPaginationParams from 'utils/formatPaginationParams';
import parsePaginationHeaders from 'utils/parsePaginationHeaders';

import Pagination from 'types/Pagination';

type ThunkActionTypes = ThunkAction<void, AppState, unknown, any>;

export const customersActionCreators = {
    setSearchParams: (payload) => ({type: actionListTypes.SET_CUSTOMERS_SEARCH_PARAMS, payload} as const),
    setCurrentPage: (payload) => ({type: actionListTypes.SET_CUSTOMERS_CURRENT_PAGE, payload} as const),
    setPerPage: (payload) => ({type: actionListTypes.SET_CUSTOMERS_PER_PAGE, payload} as const),
    receiveCustomers: (payload: {customers: Customer[]; pagination: Pagination}) =>
        ({type: actionListTypes.RECEIVED_CUSTOMERS, payload} as const),
    clearState: () => ({type: actionListTypes.CLEAR_CUSTOMERS_STATE} as const),
};

const fetchCustomersList = (generalParams, currentSearchParams) =>
    searchCustomersApiReq({...generalParams, ...currentSearchParams});

export const fetchCustomers = (): ThunkActionTypes => async (dispatch, getState) => {
    const state = getState();

    const isShowDeleted = getShowDeleted(state, ENTITY_NAME_CUSTOMERS);
    const currentSortFrom = getCurrentSortFrom(state, 'customers');
    const currentSearchParams = selectors.getSearchParams(state);
    const currentSortBy = getCurrentSortBy(state, 'customers');
    const pagination = selectors.getPagination(state);

    const generalSearchParams = {
        ...formatPaginationParams(pagination),
        is_deleted: isShowDeleted ? 1 : 0,
        sortBy: currentSortBy ? {[currentSortBy]: currentSortFrom} : {},
    };

    dispatch(appActions.showLoader());

    try {
        const {data, headers} = await fetchCustomersList(
            generalSearchParams,
            transformSearchDataToRequest(currentSearchParams),
        );

        const receiveCustomersPayload = {customers: data, pagination: parsePaginationHeaders(headers)};

        dispatch(customersActionCreators.receiveCustomers(receiveCustomersPayload));
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const setArchivedCustomers = (params: {isArchived: boolean}): ThunkActionTypes => (dispatch) => {
    const {isArchived} = params;

    dispatch(customersActionCreators.setCurrentPage(1));
    dispatch(adminActions.setAdminSettings({customers: {showDeleted: isArchived}}));
    dispatch(fetchCustomers());
};
