import React from 'react';

import CreatableMultiselect from 'components/ui/Form/MultiselectTagDropdown/components/CreatableMultiselect';

import * as regexps from 'utils/validation/regexps';

type Option = {label: string; value: string};
export type EmailsMultiSelectProps = React.ComponentProps<typeof CreatableMultiselect>;

const MultiSelect: React.FC<EmailsMultiSelectProps> = (props) => {
    const {input} = props;

    const handleChange = (options) => {
        const parsedEmails: Option[] = [];
        const emailsValues: string[] = [];

        (options || []).forEach(({value}) => {
            const emails = value.split(',');

            emails.forEach((email) => {
                if (regexps.EMAIL.test(email) && !emailsValues.includes(email)) {
                    emailsValues.push(email);
                    parsedEmails.push({label: email, value: email});
                }
            });
        });

        input.onChange(parsedEmails);
    };

    return <CreatableMultiselect {...props} input={{...input, onChange: handleChange}} />;
};

export default MultiSelect;
