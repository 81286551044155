import React from 'react';
import classNames from 'classnames';
import {Field} from 'redux-form';

import normalizeEmails from 'components/ui/Form/EmailsSelect/normalizeEmails';

import * as regexps from 'utils/validation/regexps';

import MultiSelect, {EmailsMultiSelectProps} from './components/MultiSelect';

type SelectProps = 'input' | 'meta' | 'isValidNewOption' | 'getNewOptionData';
type OwnProps = Omit<EmailsMultiSelectProps, SelectProps> & {className?: string};

const MultiCreatableEmailsSelect: React.FC<OwnProps> = (props) => {
    const {label, className, placeholder = 'type one or more emails'} = props;

    const isValidNewOption = (inputValue) => {
        if (inputValue) {
            const normalizedValue = normalizeEmails(inputValue);
            const result = normalizedValue.split(',').map((value) => regexps.EMAIL.test(value));

            return result.every((element) => element === true);
        }

        return false;
    };

    const getNewOptionData = (value) => ({
        value: normalizeEmails(value),
        label: value,
    });

    return (
        <div className={classNames('d-flex flex-column', className)}>
            {label ? <label className="form-label">{label}</label> : null}

            <Field
                {...props}
                isMulti
                isCreatable
                closeMenuOnSelect={false}
                placeholder={placeholder}
                component={MultiSelect}
                isValidNewOption={isValidNewOption}
                getNewOptionData={getNewOptionData}
            />
        </div>
    );
};

export default MultiCreatableEmailsSelect;
