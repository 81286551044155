import {AppState} from 'store';

export const getSentChecked = (state: AppState) => state.loadBoard.quoteReceivers.sentChecked;
export const getNotSentChecked = (state: AppState) => state.loadBoard.quoteReceivers.notSentChecked;
export const getDeclinedChecked = (state: AppState) => state.loadBoard.quoteReceivers.declinedChecked;
export const getReadChecked = (state: AppState) => state.loadBoard.quoteReceivers.readChecked;
export const getProvidedChecked = (state: AppState) => state.loadBoard.quoteReceivers.providedChecked;
export const getCurrentItems = (state: AppState) => state.loadBoard.quoteReceivers.currentItems;
export const getAllItems = (state: AppState) => state.loadBoard.quoteReceivers.allItems;
export const getActiveOptions = (state: AppState) => state.loadBoard.quoteReceivers.activeOptions;
