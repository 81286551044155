import omit from 'lodash/omit';

import {InferActions} from 'store';

import {offerStatus} from 'core/entities/Quote/constants/offerStatus';
import {DriverOffer, DriverReceiver, Quote} from 'core/entities/Quote/types';

import * as actionCreators from 'pages/LoadBoard/redux/actionCreators';
import * as types from 'pages/LoadBoard/redux/actionTypes';

import {addItem, addListItems, getData, removeItem, updateItem} from 'utils/normalizer';

import {ListState} from './types';
import {calculateExpiredOffers, isWebsocketAction} from './utils';
import websocketsReducer from './websockets';

export const defaultState: ListState = {
    type: 'general',
    quotes: {
        byId: {},
        allIds: [],
    },
    quoteReceivers: {
        byId: {},
        allIds: [],
    },
    sendingQuotes: {},
    offers: {
        byId: {},
        allIds: [],
    },
    expandedOffers: {},
    expiredOffers: {},
    pagination: {
        currentPage: 1,
        perPage: 50,
        totalItemsCount: 0,
        pagesCount: 0,
    },
    searchParams: {},
};

type ListReducerActions = Pick<
    typeof actionCreators,
    | 'receivedQuotes'
    | 'receivedQuoteDriversReceivers'
    | 'startedSendQuote'
    | 'completedSendQuote'
    | 'toggleExpandQuoteOffers'
    | 'resendQuote'
    | 'archiveQuote'
    | 'receivedQuoteGeneralNotes'
    | 'createdQuoteGeneralNote'
    | 'deletedQuoteGeneralNote'
    | 'changedListType'
    | 'setSearchParamsQuoteNumber'
    | 'setSearchParams'
    | 'addedDriverOffer'
    | 'updateDriverOffer'
    | 'readDriverOffer'
    | 'expiredDriverOffer'
    | 'changeStatusDriverOffer'
    | 'deactivateDriverOffer'
    | 'receivedDriverOffers'
    | 'changedPaginationPage'
    | 'changedPaginationCount'
    | 'sseDriverOffersUpdated'
    | 'sseQuotesReceiversUpdated'
    | 'clearState'
>;

type ActionsTypes = InferActions<ListReducerActions>;

export default function listReducer(state = defaultState, action: ActionsTypes): ListState {
    if (isWebsocketAction(action)) {
        return websocketsReducer(state, action as any);
    }

    switch (action.type) {
        case types.RECEIVED_QUOTES: {
            return {
                ...state,
                quotes: getData<Quote>(action.payload.quotes),
                pagination: action.payload.pagination,
            };
        }

        case types.RECEIVED_QUOTE_DRIVERS_RECEIVERS: {
            return {
                ...state,
                quoteReceivers: getData<DriverReceiver>(action.payload.receivers),
            };
        }

        case types.STARTED_SEND_QUOTE: {
            return {
                ...state,
                sendingQuotes: {...state.sendingQuotes, [action.payload.quoteID]: true},
            };
        }

        case types.COMPLETED_SEND_QUOTE: {
            return {
                ...state,
                sendingQuotes: {...state.sendingQuotes, [action.payload.quoteID]: false},
            };
        }

        case types.RESEND_QUOTE: {
            const {quote: resendQuote} = action.payload;
            if (!resendQuote) {
                return state;
            }
            const currentQuote = state.quotes.byId[resendQuote.id];
            if (!currentQuote) {
                return state;
            }
            const {status, notifiedDriversCount} = resendQuote;
            const updatedQuote = {...currentQuote, status, notifiedDriversCount};
            return {
                ...state,
                quotes: updateItem(state.quotes, updatedQuote),
            };
        }

        case types.ARCHIVE_QUOTE: {
            const {quote: archivedQuote} = action.payload;
            if (!archivedQuote) {
                return state;
            }
            const currentQuote = state.quotes.byId[archivedQuote.id];
            if (!currentQuote) {
                return state;
            }
            return {
                ...state,
                quotes: removeItem(state.quotes, currentQuote),
            };
        }

        case types.RECEIVED_QUOTE_GENERAL_NOTES: {
            const {quoteID, generalNotes} = action.payload;
            const quote = state.quotes.byId[quoteID];
            if (!quote) {
                return state;
            }

            const updatedQuote = {...quote, notes: {...quote.notes, general: generalNotes}};
            return {
                ...state,
                quotes: updateItem(state.quotes, updatedQuote),
            };
        }

        case types.CREATED_QUOTE_GENERAL_NOTE: {
            const {generalNote} = action.payload;
            const quoteID = generalNote?.quoteID;
            const quote = state.quotes.byId[quoteID];
            if (!quote) {
                return state;
            }
            const currentGeneralNotes = quote.notes.general || [];
            const updatedQuote = {
                ...quote,
                notes: {...quote.notes, general: [...currentGeneralNotes, generalNote]},
            };
            return {
                ...state,
                quotes: updateItem(state.quotes, updatedQuote),
            };
        }

        case types.DELETED_QUOTE_GENERAL_NOTE: {
            const {quoteID, noteID} = action.payload;
            const quote = state.quotes.byId[quoteID];
            if (!quote) {
                return state;
            }
            const currentGeneralNotes = quote.notes.general || [];
            const updatedNotes = {...quote.notes, general: currentGeneralNotes.filter((n) => n.id !== noteID)};
            const updatedQuote = {...quote, notes: updatedNotes};
            return {
                ...state,
                quotes: updateItem(state.quotes, updatedQuote),
            };
        }

        case types.CHANGED_QUOTES_LIST_TYPE: {
            return {
                ...state,
                type: action.payload.type,
                pagination: {...state.pagination, currentPage: 1},
            };
        }

        case types.SET_SEARCH_PARAMS_QUOTE_NUMBER: {
            return {
                ...state,
                searchParams: {quoteNumber: action.payload.quoteNumber},
            };
        }

        case types.SET_SEARCH_PARAMS: {
            return {
                ...state,
                searchParams: action.payload,
                pagination: {
                    ...state.pagination,
                    currentPage: 1,
                },
            };
        }

        case types.TOGGLE_EXPAND_QUOTE_OFFERS: {
            const {quote} = action.payload;
            const isAlreadyExpanded = state.expandedOffers[quote.id];
            return {
                ...state,
                expandedOffers: isAlreadyExpanded
                    ? omit(state.expandedOffers, quote.id)
                    : {...state.expandedOffers, [quote.id]: true},
            };
        }

        case types.RECEIVED_DRIVER_OFFERS: {
            const {driversOffers, quoteID} = action.payload;
            const quote = state.quotes.byId[quoteID];
            if (!quote) {
                return state;
            }
            const isOffersNotSet = state.offers.allIds.length === 0;
            const offers = isOffersNotSet
                ? getData<DriverOffer>(driversOffers)
                : addListItems<DriverOffer>(state.offers, driversOffers);

            return {
                ...state,
                offers,
                expiredOffers: calculateExpiredOffers(offers, state.expiredOffers),
            };
        }

        case types.ADDED_DRIVER_OFFER: {
            const driverOffer = action.payload;
            const quote = state.quotes.byId[driverOffer.quoteID];
            if (!quote) {
                return state;
            }
            const updatedQuote: Quote = {
                ...quote,
                allDriversOffersCount: quote.allDriversOffersCount + 1,
                newDriversOffersCount: quote.newDriversOffersCount + 1,
            };
            return {
                ...state,
                quotes: updateItem<Quote>(state.quotes, updatedQuote),
                offers: addItem<DriverOffer>(state.offers, driverOffer, {insertToBegin: true}),
            };
        }

        case types.UPDATED_DRIVER_OFFER: {
            const driverOffer = action.payload;
            const offerForUpdate = state.offers.byId[driverOffer.id];
            const quote = state.quotes.byId[driverOffer.quoteID];

            if (!offerForUpdate || !quote) {
                return state;
            }

            const updatedOffer = {...offerForUpdate, ...driverOffer};
            const updatedOffers = updateItem<DriverOffer>(state.offers, updatedOffer);

            return {...state, offers: updatedOffers};
        }

        case types.READ_DRIVER_OFFER: {
            const {offerID} = action.payload;
            const offerForUpdate = state.offers.byId[offerID];
            const quote = state.quotes.byId[offerForUpdate?.quoteID];

            if (!offerForUpdate || !quote) {
                return state;
            }

            const countNewOffers = quote.newDriversOffersCount;
            const updatedQuote: Quote = {
                ...quote,
                newDriversOffersCount: countNewOffers > 0 ? countNewOffers - 1 : countNewOffers,
            };
            const updatedOffer: DriverOffer = {...offerForUpdate, isReadByAllDispatchers: true};
            const updatedQuotes = updateItem<Quote>(state.quotes, updatedQuote);
            const updatedOffers = updateItem<DriverOffer>(state.offers, updatedOffer);

            return {...state, quotes: updatedQuotes, offers: updatedOffers};
        }

        case types.EXPIRED_DRIVER_OFFER: {
            const driverOffer = action.payload;

            if (!driverOffer) {
                return state;
            }

            return {...state, expiredOffers: {...state.expiredOffers, [driverOffer.id]: true}};
        }

        case types.CHANGED_DRIVER_OFFER_STATUS: {
            const driverOffer = action.payload;
            const offerForUpdate = state.offers.byId[driverOffer.id];
            const quote = state.quotes.byId[driverOffer.quoteID];

            if (!offerForUpdate || !quote) {
                return state;
            }

            const updatedOffer = {...offerForUpdate, status: driverOffer.status};
            const updatedOffers = updateItem<DriverOffer>(state.offers, updatedOffer);

            return {...state, offers: updatedOffers};
        }

        case types.DEACTIVATED_DRIVER_OFFER: {
            const driverOffer = action.payload;
            const offerForDeactivate = state.offers.byId[driverOffer.id];
            const quote = state.quotes.byId[driverOffer.quoteID];

            if (!offerForDeactivate || !quote) {
                return state;
            }

            const updatedOffer = {...offerForDeactivate, status: offerStatus.DEACTIVATED};
            const updatedOffers = updateItem<DriverOffer>(state.offers, updatedOffer);

            return {...state, offers: updatedOffers};
        }

        case types.CHANGED_QUOTES_PAGINATION_PAGE: {
            const {page} = action.payload;
            return {...state, pagination: {...state.pagination, currentPage: page}};
        }

        case types.CHANGED_QUOTES_PAGINATION_COUNT: {
            const {count} = action.payload;
            return {...state, pagination: {...state.pagination, currentPage: 1, perPage: count}};
        }

        case types.SSE_DRIVER_OFFERS_UPDATED: {
            const {offers} = action.payload;

            return {
                ...state,
                offers,
            };
        }

        case types.SSE_QUOTES_RECEIVERS_UPDATED: {
            const {quoteReceivers} = action.payload;

            return {
                ...state,
                quoteReceivers,
            };
        }

        case types.CLEAR_STATE: {
            return defaultState;
        }

        // no default
    }

    // eslint-disable-next-line
    const _exhaustiveCheck: never = action;

    return state;
}
