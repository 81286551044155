import {transformToStringEmails} from 'core/entities/LocationEvents/mappers/brokerUpdate';
import {BrokerUpdate} from 'core/entities/LocationEvents/types';

type UpdatedValues = {
    from?: string;
    to?: string;
    subject?: string;
    message?: string;
};

export const getUpdatedValues = (brokerUpdate?: BrokerUpdate): UpdatedValues => {
    const listEmailsToSendUpdated = brokerUpdate?.emails
        ? transformToStringEmails(brokerUpdate.emails, ', ')
        : undefined;

    return {
        from: brokerUpdate?.from,
        to: listEmailsToSendUpdated,
        subject: brokerUpdate?.subject,
        message: brokerUpdate?.text,
    };
};
