import {useDispatch} from 'react-redux';

import * as actionCreators from 'pages/LoadBoard/redux/actionCreators';

type QuoteReceiversActions = {
    setQuoteReceiversModalItems: typeof actionCreators.setQuoteReceiversModalItems;
    toggleAllowedSendToDriver: typeof actionCreators.toggleAllowedSendToDriver;
    toggleNotAllowedSendToDriver: typeof actionCreators.toggleNotAllowedSendToDriver;
    toggleDriverDeclinedQuote: typeof actionCreators.toggleDriverDeclinedQuote;
    toggleDriverReadQuote: typeof actionCreators.toggleDriverReadQuote;
    toggleDriverProvidedQuote: typeof actionCreators.toggleDriverProvidedQuote;
};

const useQuoteReceiversActions = (): QuoteReceiversActions => {
    const dispatch = useDispatch();

    return {
        setQuoteReceiversModalItems: ({driversReceivers}) =>
            dispatch(actionCreators.setQuoteReceiversModalItems({driversReceivers})),
        toggleAllowedSendToDriver: () => dispatch(actionCreators.toggleAllowedSendToDriver()),
        toggleNotAllowedSendToDriver: () => dispatch(actionCreators.toggleNotAllowedSendToDriver()),
        toggleDriverDeclinedQuote: () => dispatch(actionCreators.toggleDriverDeclinedQuote()),
        toggleDriverReadQuote: () => dispatch(actionCreators.toggleDriverReadQuote()),
        toggleDriverProvidedQuote: () => dispatch(actionCreators.toggleDriverProvidedQuote()),
    };
};

export default useQuoteReceiversActions;
