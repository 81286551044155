import React, {useEffect} from 'react';
import {Field, InjectedFormProps, reduxForm} from 'redux-form';

import Button from 'components/ui/Buttons/Button';
import TextInput from 'components/ui/Form/TextInput';
import TooltipBootstrap from 'components/ui/TooltipBootstrap';

import useAttachActions from 'pages/Loads/components/common/modals/AttachModal/hooks/useAttachActions';
import useAttachState from 'pages/Loads/components/common/modals/AttachModal/hooks/useAttachState';
import {createInitTrucksSearchFormValues} from 'pages/Loads/components/common/modals/AttachModal/utils';
import {TrucksSearchFormValues} from 'pages/Loads/types/formTypes';

import {normalizeNumberLetterHyphenInput} from 'utils';
import {getTypeFieldNameFactory} from 'utils/typeScript';

import useChangeReduxFormValues from 'hooks/reduxForm/useChangeReduxFormValues';
import useResetReduxForm from 'hooks/reduxForm/useResetReduxForm';

import styles from './styles.module.scss';

const getName = getTypeFieldNameFactory<TrucksSearchFormValues>();

const TrucksSearchForm: React.FC<{} & InjectedFormProps<{}, {}>> = (props) => {
    const {handleSubmit, submitting, pristine, form} = props;

    const initTrucksSearchFormValues = useChangeReduxFormValues(form);
    const {trucksSearchParams} = useAttachState();
    const resetForm = useResetReduxForm(form);
    const {resetTrucks} = useAttachActions();

    useEffect(() => {
        const trucksSearchFormValues = createInitTrucksSearchFormValues(trucksSearchParams);

        initTrucksSearchFormValues(trucksSearchFormValues);
    }, []);

    const handleResetFields = () => {
        resetTrucks();
        resetForm();
    };

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <Field
                normalize={normalizeNumberLetterHyphenInput}
                name={getName('truckNumber')}
                component={TextInput}
                placeholder="truck#"
            />

            <div className="mr10" />

            <Field placeholder="drivers" name={getName('drivers')} component={TextInput} />

            <div className="mr10" />

            <Field placeholder="owners" name={getName('owners')} component={TextInput} />

            <div className="mr10" />

            <TooltipBootstrap tooltipContent="clear fields">
                <Button
                    onClick={handleResetFields}
                    disabled={pristine}
                    buttonSize="icon"
                    colorTheme="grey"
                    buttonIcon="undo"
                />
            </TooltipBootstrap>

            <div className="mr5" />

            <Button disabled={pristine || submitting} type="submit" buttonSize="small">
                Apply
            </Button>
        </form>
    );
};

export default reduxForm({})(TrucksSearchForm);
