import {createSelector} from 'reselect';

import {getOwnerCoordinator} from 'core/entities/Owner/modules/ownerCoordinator';
import Truck from 'core/entities/Truck/types';

import {getTruckDriverIsResponsible} from '../truckDriverResponsible';
import {getOwnerTruckDriver, getTruckDrivers} from '../truckDrivers';
import {getTruckMainPersonPrefix} from '../truckMainPerson';
import {getTruckCoordinatorIsResponsible} from '../truckOwnerCoordinatorResponsible';
import {getTruckOwnerIsResponsible} from '../truckOwnerResponsible';

export const getTruckTeam = createSelector([(truck: Truck | null | undefined) => truck], (truck) => {
    if (!truck) {
        return {};
    }

    const {owner} = truck;

    const {firstDriver, secondDriver} = getTruckDrivers(truck) || {};

    const ownerDriver = getOwnerTruckDriver(truck);
    const ownerCoordinator = getOwnerCoordinator(owner);
    const isOwnerResponsible = getTruckOwnerIsResponsible(truck);
    const mainContactPersonTitle = getTruckMainPersonPrefix(truck);
    const isCoordinatorResponsible = getTruckCoordinatorIsResponsible(truck);
    const isDriverResponsible = getTruckDriverIsResponsible(truck, firstDriver);

    return {
        owner,
        ownerDriver,
        ownerCoordinator,
        firstDriver,
        secondDriver,
        isOwnerResponsible,
        isDriverResponsible,
        isCoordinatorResponsible,
        mainContactPersonTitle,
    };
});
