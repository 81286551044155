import React, {useEffect} from 'react';

import {DriverReceiverWithTruck} from 'core/entities/Quote/types';

import useQuoteReceiversActions from 'pages/LoadBoard/hooks/useQuoteReceiversActions';
import useQuoteReceiversState from 'pages/LoadBoard/hooks/useQuoteReceiversState';

import Options from './components/Options';
import ReceiverItem from './components/ReceiverItem';

import styles from './styles.module.scss';

type OwnProps = {
    driversReceivers: DriverReceiverWithTruck[];
};

const ListReceivers: React.FC<OwnProps> = (props) => {
    const {driversReceivers} = props;

    const {setQuoteReceiversModalItems} = useQuoteReceiversActions();
    const {currentItems} = useQuoteReceiversState();

    useEffect(() => {
        setQuoteReceiversModalItems({driversReceivers});
    }, [driversReceivers]);

    return (
        <div>
            <div className={styles.statusWrapper}>
                <Options driversReceivers={driversReceivers} />
            </div>
            <div className={`standard-table-style ${styles.scroll}`}>
                <table className={`table ${styles.table}`}>
                    <thead>
                        <tr>
                            <th>TRUCKS</th>
                            <th>MAIN CONTACT</th>
                            <th>LOCATIONS</th>
                            <th>ACTIONS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((r, index) => (
                            <ReceiverItem key={index} receiver={r} />
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ListReceivers;
