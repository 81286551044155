import {AxiosResponse} from 'axios';

import restapi from 'services/restapi';

import {ENTITY_NAME_SENDERS} from 'utils/data/entityNames';

const apiUrl = `/email/${ENTITY_NAME_SENDERS}`;

export const fetchSenders = (): Promise<AxiosResponse> => {
    return restapi.get(apiUrl);
};
