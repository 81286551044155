import {InferActions} from 'store';

import {DriverReceiverWithTruck} from 'core/entities/Quote/types';

import {driverFilterOptions} from 'pages/LoadBoard/constants/quoteReceiversModal';

import * as actionCreators from '../actionCreators';
import * as types from '../actionTypes';

import {getCurrentOptions, getFilteredItems} from './utils';

export type FilterOptions =
    | 'DRIVER_READ_QUOTE'
    | 'DRIVER_DECLINED_QUOTE'
    | 'DRIVER_PROVIDED_QUOTE'
    | 'ALLOWED_SEND_TO_DRIVER'
    | 'NOT_ALLOWED_SEND_TO_DRIVER';

type QuoteReceiversState = {
    allItems: DriverReceiverWithTruck[];
    currentItems: DriverReceiverWithTruck[];
    activeOptions: FilterOptions[];
    sentChecked: boolean;
    notSentChecked: boolean;
    declinedChecked: boolean;
    readChecked: boolean;
    providedChecked: boolean;
};

export const defaultState: QuoteReceiversState = {
    allItems: [],
    currentItems: [],
    activeOptions: [
        driverFilterOptions.allowedSendToDriver,
        driverFilterOptions.notAllowedSendToDriver,
        driverFilterOptions.driverDeclinedQuote,
        driverFilterOptions.driverReadQuote,
        driverFilterOptions.driverProvidedQuote,
    ],
    sentChecked: true,
    notSentChecked: true,
    declinedChecked: true,
    readChecked: true,
    providedChecked: true,
};

type ListReducerActions = Pick<
    typeof actionCreators,
    | 'toggleDriverReadQuote'
    | 'toggleAllowedSendToDriver'
    | 'toggleDriverDeclinedQuote'
    | 'toggleDriverProvidedQuote'
    | 'setQuoteReceiversModalItems'
    | 'toggleNotAllowedSendToDriver'
    | 'quoteReceiverModalItemsUpdated'
>;

type ActionsTypes = InferActions<ListReducerActions>;

export default function listReducer(state = defaultState, action: ActionsTypes): QuoteReceiversState {
    switch (action.type) {
        case types.SET_QUOTE_RECEIVERS_MODAL_ITEMS: {
            const {driversReceivers} = action.payload;
            return {
                ...state,
                allItems: driversReceivers,
                currentItems: driversReceivers,
            };
        }
        case types.SSE_QUOTE_RECEIVERS_MODAL_ITEMS_UPDATED: {
            const {allItems, currentItems} = action.payload;
            return {
                ...state,
                allItems,
                currentItems,
            };
        }
        case types.TOGGLE_ALLOWED_SEND_TO_DRIVER: {
            const currentActiveOptions = getCurrentOptions({
                options: state.activeOptions,
                currentOption: driverFilterOptions.allowedSendToDriver,
            });

            return {
                ...state,
                sentChecked: !state.sentChecked,
                currentItems: getFilteredItems({items: state.allItems, options: currentActiveOptions}),
                activeOptions: currentActiveOptions,
            };
        }
        case types.TOGGLE_NOT_ALLOWED_SEND_TO_DRIVER: {
            const currentActiveOptions = getCurrentOptions({
                options: state.activeOptions,
                currentOption: driverFilterOptions.notAllowedSendToDriver,
            });

            return {
                ...state,
                notSentChecked: !state.notSentChecked,
                currentItems: getFilteredItems({items: state.allItems, options: currentActiveOptions}),
                activeOptions: currentActiveOptions,
            };
        }

        case types.TOGGLE_DRIVER_DECLINED_QUOTE: {
            const currentActiveOptions = getCurrentOptions({
                options: state.activeOptions,
                currentOption: driverFilterOptions.driverDeclinedQuote,
            });

            return {
                ...state,
                declinedChecked: !state.declinedChecked,
                currentItems: getFilteredItems({items: state.allItems, options: currentActiveOptions}),
                activeOptions: currentActiveOptions,
            };
        }

        case types.TOGGLE_DRIVER_READ_QUOTE: {
            const currentActiveOptions = getCurrentOptions({
                options: state.activeOptions,
                currentOption: driverFilterOptions.driverReadQuote,
            });

            return {
                ...state,
                readChecked: !state.readChecked,
                currentItems: getFilteredItems({items: state.allItems, options: currentActiveOptions}),
                activeOptions: currentActiveOptions,
            };
        }

        case types.TOGGLE_DRIVER_PROVIDED_QUOTE: {
            const currentActiveOptions = getCurrentOptions({
                options: state.activeOptions,
                currentOption: driverFilterOptions.driverProvidedQuote,
            });

            return {
                ...state,
                providedChecked: !state.providedChecked,
                currentItems: getFilteredItems({items: state.allItems, options: currentActiveOptions}),
                activeOptions: currentActiveOptions,
            };
        }

        default:
            return state;
    }

    return state;
}
