import React from 'react';
import DOMPurify from 'dompurify';
import Linkify from 'linkify-react';
import isEmpty from 'lodash/isEmpty';

import useChatState from 'widgets/Chat/hooks/useChatState';
import {MessageGroup} from 'widgets/Chat/types/group';

import DispatchersExtension from 'components/common/Dispatcher/DispatchersExtension';

import {createDate} from 'utils/dateTime';

import FilePreviewWrapper from './components/FilePreviewWrapper';
import MessageItemNotification from './components/MessageItemNotification';

import {normalizeFileToPreview} from './utils';

import styles from './styles.module.scss';

type OwnProps = {
    message: MessageGroup;
};

const MessageItem: React.FC<OwnProps> = (props) => {
    const {message} = props;

    const {driversGroupUnreadMessages, unreadInfo} = useChatState();

    const {id, createdAt, user, text, isNotification, attachments} = message || {};

    const isDispatcher = user.type === 'dispatcher';
    const isDriver = user.type === 'driver';

    const messageCreationDate = createDate(createdAt, {fromISO: true}).fullDate;

    // const isMessageUnread = Object.values(unreadInfo || {})
    //     .map((item) => item?.unreadMessagesByDispatcher || [])
    //     .flat()
    //     .find((item) => item?.messageID === message.id);

    const isUnread = driversGroupUnreadMessages?.some((item) => item === id);

    const renderAttachments = attachments.map((attachment) => (
        <FilePreviewWrapper key={attachment.id} file={normalizeFileToPreview(attachment)} />
    ));

    const messageBody = (
        <>
            {text && !isNotification ? (
                <div className="message-text">
                    <Linkify options={{target: '_blank', className: 'message-link'}}>
                        {DOMPurify.sanitize(text)}
                    </Linkify>
                </div>
            ) : null}

            {isNotification ? <MessageItemNotification message={message} /> : null}

            {!isEmpty(message.attachments) ? <div className="message-attach">{renderAttachments}</div> : null}
        </>
    );

    if (isDriver) {
        return (
            <div className={styles.driver} key={id}>
                {messageBody}

                <div className="message-date">{messageCreationDate}</div>

                {isUnread ? <div className="message-status">new</div> : null}
            </div>
        );
    }

    return (
        <div className={styles.dispatcher} key={id}>
            {isDispatcher ? (
                <div className="message-from">
                    {user.name}

                    <span className="sep">
                        <span className="dot" />
                        <span className="dot" />
                        <span className="dot" />
                    </span>

                    <DispatchersExtension extension={user.extension} />
                </div>
            ) : null}

            {messageBody}

            <div className="message-date">{messageCreationDate}</div>
        </div>
    );
};

export default MessageItem;
