import {useSelector} from 'react-redux';

import * as selectors from 'pages/LoadBoard/redux/selectors/quoteReceivers';

const useQuoteReceiversState = () => {
    const sentChecked = useSelector(selectors.getSentChecked);
    const notSentChecked = useSelector(selectors.getNotSentChecked);
    const declinedChecked = useSelector(selectors.getDeclinedChecked);
    const readChecked = useSelector(selectors.getReadChecked);
    const providedChecked = useSelector(selectors.getProvidedChecked);
    const currentItems = useSelector(selectors.getCurrentItems);
    const allItems = useSelector(selectors.getAllItems);
    const activeOptions = useSelector(selectors.getActiveOptions);

    return {
        sentChecked,
        notSentChecked,
        declinedChecked,
        readChecked,
        providedChecked,
        currentItems,
        allItems,
        activeOptions,
    };
};

export default useQuoteReceiversState;
