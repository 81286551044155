import {ROUTES_PATHS} from 'routing/constants';
import {RoutesNames} from 'routing/types';

import {
    auctionSSEEventHandler,
    tripMonitorSSEEventHandler,
    trucksListSSEEventHandler,
    trucksNearbySSEEventHandler,
} from './handlers';

export const handlersMap: Partial<Record<RoutesNames, any>> = {
    [ROUTES_PATHS.auction]: auctionSSEEventHandler,
    [ROUTES_PATHS.trucksList]: trucksListSSEEventHandler,
    [ROUTES_PATHS.tripMonitor]: tripMonitorSSEEventHandler,
    [ROUTES_PATHS.trucksNearby]: trucksNearbySSEEventHandler,
};

export const pathsMap = {
    [ROUTES_PATHS.auction]: 'tnb',
    [ROUTES_PATHS.trucksList]: 'tnb',
    [ROUTES_PATHS.tripMonitor]: 'trip_monitor',
    [ROUTES_PATHS.trucksNearby]: 'tnb',
};
