import React, {useEffect} from 'react';
import {Col, Row} from 'react-bootstrap';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';
import {Field, InjectedFormProps, reduxForm} from 'redux-form';

import {LocationEventsTypes} from 'core/entities/LocationEvents/constants';
import {GetBrokerUpdateDataFromRequest} from 'core/entities/LocationEvents/types';
import {SENDER_TYPE_UPDATE} from 'core/entities/Sender/constants';
import {transferTOFileToNewFileInterface} from 'core/entities/TravelOrder/modules/travelOrderAttachments';

import Button from 'components/ui/Buttons/Button';
import FilePicker from 'components/ui/Files/FilePicker';
import MultiCreatableEmailsSelect from 'components/ui/Form/MultiCreatableEmailsSelect';
import SingleSelect from 'components/ui/Form/SingleSelect';
import SingleSendersByTypeAsyncSelect from 'components/ui/Form/SingleSendersByTypeAsyncSelect';
import TextArea from 'components/ui/Form/TextArea';
import TextInputBlockable from 'components/ui/Form/TextInputBlockable';

import {getTypeFieldNameFactory} from 'utils/typeScript';

import useChangeReduxFormValue from 'hooks/reduxForm/useChangeReduxFormValue';
import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';

import {GroupedFilesTravelOrder} from 'types/File';

import {BrokerUpdateSendFormValues} from '../../types';
import {getListEmails} from '../../utils';

import validation from './validation';

import styles from './styles.module.scss';

type OwnProps = {
    updateTypeOptions: GetBrokerUpdateDataFromRequest['updateTypeOptions'];
    updateTypeMessages: Record<LocationEventsTypes, string[]>;
    files: GroupedFilesTravelOrder;
    brokerEmailsData: string[];
    emailFrom?: string | null;
    onClose: () => void;
};

const BROKER_UPDATE_SEND_FORM_NAME = 'broker-update-send';
const getName = getTypeFieldNameFactory<BrokerUpdateSendFormValues>();

const BrokerUpdateSendForm: React.FC<InjectedFormProps<{}, OwnProps> & OwnProps> = (props) => {
    const {
        updateTypeMessages,
        updateTypeOptions,
        brokerEmailsData,
        handleSubmit,
        emailFrom,
        onClose,
        files,
        form,
    } = props;

    const emailsTo = useGetReduxFormValue(form, getName('emailsTo'));
    const messageTextForCopy = useGetReduxFormValue(form, getName('message'));
    const updateType = useGetReduxFormValue(form, getName('updateType'));
    const changeFormValue = useChangeReduxFormValue(form);

    const transferredAttachments = mapValues(files, (items) =>
        items.map((file) => transferTOFileToNewFileInterface({file})),
    );

    const brokerEmailsOptions = getListEmails(brokerEmailsData);

    const emailsTextForCopy = (emailsTo || []).map(({value}) => value).join(',');

    useEffect(() => {
        changeFormValue(getName('message'), updateTypeMessages[updateType?.value]?.join('\n'));
    }, [updateType]);

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.content}>
                <div className={styles.from}>
                    <Row>
                        <Col md={7}>
                            <SingleSendersByTypeAsyncSelect
                                placeholder="type one or more emails"
                                senderType={SENDER_TYPE_UPDATE}
                                name={getName('emailSender')}
                                email={emailFrom}
                                isFetchOnMount
                                label="From"
                                form={form}
                            />
                        </Col>
                    </Row>
                </div>
                <div className={styles.to}>
                    <MultiCreatableEmailsSelect
                        placeholder="type one or more emails"
                        className={styles.emailsSelect}
                        options={brokerEmailsOptions}
                        name={getName('emailsTo')}
                        label="To"
                    />
                    <CopyToClipboard text={emailsTextForCopy}>
                        <Button colorTheme="grey" buttonSize="icon" buttonIcon="files-o" />
                    </CopyToClipboard>
                </div>
                <div className={classNames(styles.subject, 'mt10')}>
                    <Field
                        name={getName('subject')}
                        type="text"
                        component={TextInputBlockable}
                        label="Subject"
                        disabled
                    />
                </div>
                <div className={styles.typeUpdate}>
                    <Row>
                        <Col md={7}>
                            <SingleSelect
                                label="update Type"
                                name={getName('updateType')}
                                options={updateTypeOptions}
                                isClearable={false}
                            />
                        </Col>
                    </Row>
                </div>
                <div className={styles.message}>
                    <Field
                        name={getName('message')}
                        type="text"
                        maxLength="750"
                        component={TextArea}
                        rows="9"
                        label="Message"
                        className={styles.scroll}
                    />
                    <CopyToClipboard text={messageTextForCopy}>
                        <Button colorTheme="grey" buttonIcon="files-o" buttonSize="icon" />
                    </CopyToClipboard>
                </div>
                {!isEmpty(files) ? (
                    <div className={styles.attachments}>
                        <Field name={getName('attachments')} component={FilePicker} files={transferredAttachments} />
                    </div>
                ) : null}
            </div>

            <div className="modal-body__bottom">
                <Button onClick={onClose}>Cancel</Button>
                <Button type="submit" colorTheme="blue">
                    Send to Broker
                </Button>
            </div>
        </form>
    );
};

export default reduxForm<{}, OwnProps>({
    form: BROKER_UPDATE_SEND_FORM_NAME,
    validate: validation,
})(BrokerUpdateSendForm);
