import {handleSSEData as handleLoadBoardSSEData} from 'pages/LoadBoard/redux/actions';
import {tripMonitorActionCreators} from 'pages/TravelOrders/redux/actions/list';
import {setSSEData as setTrucksListSSEData} from 'pages/Trucks/actions/list';
import {setSSEData as setTNBSSEData} from 'pages/TrucksNearby/redux/actions/list';

export const tripMonitorSSEEventHandler = ({dispatch, data}) => {
    dispatch(tripMonitorActionCreators.setSSEData({data}));
};

export const trucksNearbySSEEventHandler = ({dispatch, data}) => {
    dispatch(setTNBSSEData({data}));
};

export const trucksListSSEEventHandler = ({dispatch, data}) => {
    dispatch(setTrucksListSSEData({data}));
};

export const auctionSSEEventHandler = ({dispatch, data}) => {
    dispatch(handleLoadBoardSSEData({data}));
};
