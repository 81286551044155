import React from 'react';
import filter from 'lodash/filter';

import {SEND_MESSAGE_FORM} from 'widgets/Chat/constants';
import useChatActions from 'widgets/Chat/hooks/useChatActions';
import useChatState from 'widgets/Chat/hooks/useChatState';
import {MessageFormValues, NormalizedFormFile} from 'widgets/Chat/types/form';

import Button from 'components/ui/Buttons/Button';
import {ACCEPTED_IMAGE_TYPES} from 'components/ui/Files/FileUpload/constants';

import {getTypeFieldNameFactory} from 'utils/typeScript';

import useChangeReduxFormValue from 'hooks/reduxForm/useChangeReduxFormValue';
import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';

import styles from './styles.module.scss';

type OwnProps = {
    file: NormalizedFormFile;
};

const getName = getTypeFieldNameFactory<MessageFormValues>();

const AttachPreviewImage: React.FC<OwnProps> = (props) => {
    const {file} = props;

    const attachments = useGetReduxFormValue(SEND_MESSAGE_FORM, getName('attachments'));
    const changeFormValue = useChangeReduxFormValue(SEND_MESSAGE_FORM);
    const {clearMessageAttachments} = useChatActions();
    const {isAttachmentsUploading} = useChatState();

    const isImage = ACCEPTED_IMAGE_TYPES.includes(file.extension.toLowerCase());

    const handleRemoveAttachmentFromPreview = () => {
        clearMessageAttachments();
        changeFormValue(
            getName('attachments'),
            filter(attachments, (item) => item.id !== file.id),
        );
    };

    const attachPreviewButtonJSX = isAttachmentsUploading ? (
        <Button buttonSize="icon" buttonIcon="spinner fa-pulse" className="close-btn" />
    ) : (
        <Button
            onClick={handleRemoveAttachmentFromPreview}
            buttonSize="icon"
            buttonIcon="times"
            className="close-btn"
        />
    );

    return (
        <div className={styles.grid}>
            {attachPreviewButtonJSX}

            {isImage ? <img src={file.url} alt="uploaded image" /> : file.extension}
        </div>
    );
};

export default AttachPreviewImage;
