import React, {useCallback} from 'react';
import {Modal} from 'react-bootstrap';

import {FIELDS} from 'core/entities/Customer/constants/fields';

import GeneralTab from 'pages/Customers/components/Forms/Tabs/GeneralTab';

import identifierTypes from 'utils/data/identifierTypes';

import useGetIsReduxFormWasReset from 'hooks/reduxForm/useGetIsReduxFormWasReset';

import styles from './createCustomer.module.scss';

const formName = 'load_card_customer_create_form';

type OwnProps = {
    onSubmit: (formValues) => void;
    onClose: () => void;
    onCancel: () => void;
};

const CreateCustomer: React.FC<OwnProps> = (props) => {
    const {onSubmit, onCancel, onClose} = props;
    const {resetForm, isNeedReset} = useGetIsReduxFormWasReset(formName);

    const handleReset = useCallback(() => resetForm(formName), []);
    const handleCancel = useCallback(() => {
        if (!isNeedReset) {
            onCancel();
            return null;
        }
        onClose();
    }, [isNeedReset]);

    const initialValues = {
        [FIELDS.billing_method]: 'factored',
        [FIELDS.payment_terms]: 'net 30 days',
        [FIELDS.is_customer]: true,
        [FIELDS.is_active]: true,
        [FIELDS.in_black_list]: false,
        [FIELDS.identifier_type]: identifierTypes.MC.shortName,
        [FIELDS.customerType]: null,
        [FIELDS.customerTypeNote]: null,
        [FIELDS.isThirdApiBroker]: false,
        [FIELDS.apiConnection]: null,
    };

    return (
        <Modal onHide={onClose} dialogClassName="standard-form-modal" backdrop="static" show>
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>Create New Customer</Modal.Title>
                    <button onClick={onClose} type="button" className="close">
                        <i className="fa fa-times" aria-hidden="true" />
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className={styles.modal}>
                    <GeneralTab
                        form={formName}
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        onReset={handleReset}
                        onCancel={handleCancel}
                        submitTitle="Create Customer"
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default CreateCustomer;
