import React from 'react';
import {Modal} from 'react-bootstrap';

import TravelOrder from 'core/entities/TravelOrder/types';

import Form from './components/Form';
import useTimerSettings from './hooks/useTimerSettings';

type OwnProps = {
    handlers: {onSetEventTimer: (timerSettings) => void};
    data: {travelOrder: TravelOrder};
    onClose: () => void;
};

const TimerSettingsModal: React.FC<OwnProps> = (props) => {
    const {data, handlers, onClose} = props;
    const {onSetEventTimer} = handlers;
    const {travelOrder} = data;

    const {
        initialFormValues,
        brokerEmailsOptions,
        emailFrom,
        canCreateAutoCheckCall,
        distanceDiffer,
    } = useTimerSettings({travelOrder, onClose});

    const onSubmit = (values) => {
        onSetEventTimer(values);
    };

    if (!initialFormValues) {
        return null;
    }

    return (
        <div className="modal-dialog modal-content standard-form-modal modal-md">
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>Check Call settings</Modal.Title>
                    <button onClick={onClose} type="button" className="close">
                        <i className="fa fa-times" aria-hidden="true" />
                    </button>
                </div>
            </Modal.Header>

            <Modal.Body>
                {/* @ts-ignore */}
                <Form
                    initialValues={initialFormValues}
                    travelOrder={travelOrder}
                    onSubmit={onSubmit}
                    onClose={onClose}
                    canCreateAutoCheckCall={canCreateAutoCheckCall}
                    brokerEmailsOptions={brokerEmailsOptions}
                    emailFrom={emailFrom}
                    distanceDiffer={distanceDiffer}
                />
            </Modal.Body>
        </div>
    );
};

export default TimerSettingsModal;
