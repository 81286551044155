import {DriverOffer, DriverReceiver, DriverReceiverWithTruck, GeneralNote, Quote} from 'core/entities/Quote/types';

import {NormalizedData} from 'utils/normalizer';

import Pagination from 'types/Pagination';

import {ListType, QuoteSearchParams} from '../../types';
import * as types from '../actionTypes';

export const receivedQuotes = (data: Quote[], pagination: Pagination) => {
    return {
        type: types.RECEIVED_QUOTES,
        payload: {quotes: data, pagination},
    } as const;
};

export const receivedQuoteDriversReceivers = (quoteID: Quote['id'], receivers: DriverReceiver[]) => {
    return {
        type: types.RECEIVED_QUOTE_DRIVERS_RECEIVERS,
        payload: {quoteID, receivers},
    } as const;
};

export const startedSendQuote = (quoteID: Quote['id']) => {
    return {
        type: types.STARTED_SEND_QUOTE,
        payload: {quoteID},
    } as const;
};

export const completedSendQuote = (quoteID: Quote['id']) => {
    return {
        type: types.COMPLETED_SEND_QUOTE,
        payload: {quoteID},
    } as const;
};

export const resendQuote = (quote: Quote) => {
    return {
        type: types.RESEND_QUOTE,
        payload: {quote},
    } as const;
};

export const toggleExpandQuoteOffers = (quote: Quote) => {
    return {
        type: types.TOGGLE_EXPAND_QUOTE_OFFERS,
        payload: {quote},
    } as const;
};

export const archiveQuote = (quote: Quote) => {
    return {
        type: types.ARCHIVE_QUOTE,
        payload: {quote},
    } as const;
};

export const receivedQuoteGeneralNotes = (quotID: Quote['id'], generalNotes: GeneralNote[]) => {
    return {
        type: types.RECEIVED_QUOTE_GENERAL_NOTES,
        payload: {quoteID: quotID, generalNotes},
    } as const;
};

export const createdQuoteGeneralNote = (generalNote: GeneralNote) => {
    return {
        type: types.CREATED_QUOTE_GENERAL_NOTE,
        payload: {generalNote},
    } as const;
};

export const changedListType = (newType: ListType) => {
    return {
        type: types.CHANGED_QUOTES_LIST_TYPE,
        payload: {type: newType},
    } as const;
};

export const goToGeneralList = () => {
    return changedListType('general');
};

export const goToArchivedList = () => {
    return changedListType('archived');
};

export const changedPaginationPage = (page: number) => {
    return {
        type: types.CHANGED_QUOTES_PAGINATION_PAGE,
        payload: {page},
    } as const;
};

export const changedPaginationCount = (count: number) => {
    return {
        type: types.CHANGED_QUOTES_PAGINATION_COUNT,
        payload: {count},
    } as const;
};

export const deletedQuoteGeneralNote = (quotID: Quote['id'], noteID: string) => {
    return {
        type: types.DELETED_QUOTE_GENERAL_NOTE,
        payload: {quoteID: quotID, noteID},
    } as const;
};

export const createdQuoteGeneralNoteWS = (createdNote: GeneralNote) => {
    return {
        type: types.CREATED_QUOTE_GENERAL_NOTE_WS,
        payload: {generalNote: createdNote},
    } as const;
};

export const deletedQuoteGeneralNoteWS = (deletedNote: GeneralNote) => {
    return {
        type: types.DELETED_QUOTE_GENERAL_NOTE_WS,
        payload: {generalNote: deletedNote},
    } as const;
};

export const activatedQuoteWS = (params: {quoteID: string; status: Quote['status']; notifiedDriversCount: number}) => {
    const {quoteID, status, notifiedDriversCount} = params;
    return {
        type: types.ACTIVATED_QUOTE_WS,
        payload: {quoteID, status, notifiedDriversCount},
    } as const;
};

export const setSearchParamsQuoteNumber = (quoteNumber: number) => {
    return {
        type: types.SET_SEARCH_PARAMS_QUOTE_NUMBER,
        payload: {quoteNumber},
    } as const;
};

export const setSearchParams = (searchParams: QuoteSearchParams) => {
    return {
        type: types.SET_SEARCH_PARAMS,
        payload: searchParams,
    } as const;
};

export const receivedDriverOffers = (driversOffers: DriverOffer[], quoteID: string) => {
    return {
        type: types.RECEIVED_DRIVER_OFFERS,
        payload: {driversOffers, quoteID},
    } as const;
};

export const addedDriverOffer = (driverOffer: DriverOffer) => {
    return {
        type: types.ADDED_DRIVER_OFFER,
        payload: driverOffer,
    } as const;
};

export const addedDriverOfferWS = (driverOffer: DriverOffer) => {
    return {
        type: types.ADDED_DRIVER_OFFER_WS,
        payload: driverOffer,
    } as const;
};

export const updateDriverOffer = (driverOffer: DriverOffer) => {
    return {
        type: types.UPDATED_DRIVER_OFFER,
        payload: driverOffer,
    } as const;
};

export const readDriverOffer = (driverOfferID: string) => {
    return {
        type: types.READ_DRIVER_OFFER,
        payload: {offerID: driverOfferID},
    } as const;
};

export const expiredDriverOffer = (driverOffer: DriverOffer) => {
    return {
        type: types.EXPIRED_DRIVER_OFFER,
        payload: driverOffer,
    } as const;
};

export const updatedDriverOfferWS = (driverOffer: DriverOffer) => {
    return {
        type: types.UPDATED_DRIVER_OFFER_WS,
        payload: driverOffer,
    } as const;
};

export const changeStatusDriverOffer = (driverOffer: DriverOffer) => {
    return {
        type: types.CHANGED_DRIVER_OFFER_STATUS,
        payload: driverOffer,
    } as const;
};

export const changeStatusDriverOfferWS = (driverOffer: DriverOffer) => {
    return {
        type: types.CHANGED_DRIVER_OFFER_STATUS_WS,
        payload: driverOffer,
    } as const;
};

export const deactivateDriverOffer = (driverOffer: DriverOffer) => {
    return {
        type: types.DEACTIVATED_DRIVER_OFFER,
        payload: driverOffer,
    } as const;
};

export const deactivateDriverOfferWs = (driverOffer: DriverOffer) => {
    return {
        type: types.DEACTIVATED_DRIVER_OFFER_WS,
        payload: driverOffer,
    } as const;
};

export const setQuoteReceiversModalItems = ({driversReceivers}: {driversReceivers: DriverReceiverWithTruck[]}) => {
    return {
        type: types.SET_QUOTE_RECEIVERS_MODAL_ITEMS,
        payload: {driversReceivers},
    } as const;
};

export const toggleAllowedSendToDriver = () => {
    return {
        type: types.TOGGLE_ALLOWED_SEND_TO_DRIVER,
    } as const;
};

export const toggleNotAllowedSendToDriver = () => {
    return {
        type: types.TOGGLE_NOT_ALLOWED_SEND_TO_DRIVER,
    } as const;
};

export const toggleDriverDeclinedQuote = () => {
    return {
        type: types.TOGGLE_DRIVER_DECLINED_QUOTE,
    } as const;
};

export const toggleDriverReadQuote = () => {
    return {
        type: types.TOGGLE_DRIVER_READ_QUOTE,
    } as const;
};

export const toggleDriverProvidedQuote = () => {
    return {
        type: types.TOGGLE_DRIVER_PROVIDED_QUOTE,
    } as const;
};

export const sseDriverOffersUpdated = ({offers}: {offers: NormalizedData<DriverOffer>}) => {
    return {
        type: types.SSE_DRIVER_OFFERS_UPDATED,
        payload: {offers},
    } as const;
};

type QuoteReceiverModalItemsUpdatedParams = {
    allItems: DriverReceiverWithTruck[];
    currentItems: DriverReceiverWithTruck[];
};
export const quoteReceiverModalItemsUpdated = ({allItems, currentItems}: QuoteReceiverModalItemsUpdatedParams) => {
    return {
        type: types.SSE_QUOTE_RECEIVERS_MODAL_ITEMS_UPDATED,
        payload: {allItems, currentItems},
    } as const;
};

export const sseQuotesReceiversUpdated = ({
    quoteReceivers,
}: {
    quoteReceivers: NormalizedData<DriverReceiverWithTruck>;
}) => {
    return {
        type: types.SSE_QUOTES_RECEIVERS_UPDATED,
        payload: {quoteReceivers},
    } as const;
};

export const clearState = () => {
    return {
        type: types.CLEAR_STATE,
    } as const;
};
