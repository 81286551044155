import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';

import {LocationEventsTypes} from 'core/entities/LocationEvents/constants';
import {transformEmailsToOptions} from 'core/entities/LocationEvents/mappers/brokerUpdate';
import {GetBrokerUpdateDataFromRequest} from 'core/entities/LocationEvents/types';

import {GroupedFilesTravelOrder} from 'types/File';

import {BrokerUpdateSendFormValues} from '../types';

type InitProps = {
    travelOrderNumber: number;
    updateType: LocationEventsTypes;
    updateTypeOptions: GetBrokerUpdateDataFromRequest['updateTypeOptions'];
    subject: string;
    emailsTo: string[];
};

export const createInitFormValues = (props: InitProps): Partial<BrokerUpdateSendFormValues> => {
    const {travelOrderNumber, updateType, updateTypeOptions, subject, emailsTo} = props;

    return {
        travelOrderNumber,
        emailSender: null,
        emailsTo: transformEmailsToOptions(emailsTo),
        subject,
        updateType: updateTypeOptions.find((option) => option.value === updateType),
        message: '',
    };
};

export const clearEmptyStopAttachments = (attachments: GroupedFilesTravelOrder) =>
    omitBy(attachments, (value) => isEmpty(value));

export const getListEmails = (emails?: string[]): {label: string; value: string}[] => {
    return (emails && emails.map((emailItem) => ({value: emailItem, label: emailItem}))) || [];
};
