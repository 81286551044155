import isArray from 'lodash/isArray';

import {Sender, SenderType} from 'core/entities/Sender/types';

type OwnProps = {
    sendersList: Sender[];
    senderType: SenderType;
};

type SendersOptions = {
    value: number;
    label: string;
    default: boolean;
};

export const getSendersByType = ({sendersList, senderType}: OwnProps): Sender[] => {
    if (!sendersList || !isArray(sendersList)) {
        return [];
    }
    return sendersList.filter((item) => item.type === senderType);
};

export const getSendersOptions = ({sendersList, senderType}: OwnProps): SendersOptions[] => {
    const sendersByType = getSendersByType({sendersList, senderType});

    return sendersByType.map((sender: Sender) => ({
        value: sender.id,
        label: sender.email,
        default: sender.by_default,
    }));
};
