import groupBy from 'lodash/groupBy';
import head from 'lodash/head';
import isEqual from 'lodash/isEqual';
import keys from 'lodash/keys';
import set from 'lodash/set';

import {FileMetaData} from 'core/entities/TravelOrder/types/Files';

import {getAttachmentsMetaData} from 'pages/TravelOrders/redux/mappers/attachments';
import * as formTypes from 'pages/TravelOrders/types/formTypes';

import {createDate} from 'utils/dateTime';

import {getCorrespondingAttachments} from '../attachments';

export const transformPODsDataToRequestBody = (
    podData: formTypes.ProofOfDeliveryModalFormValues,
): {
    pods: Partial<{bolId: number; signedBy: string; files?: FileMetaData[]}>[];
    documentActionDateTime: string | null;
    documentActionTimeZone: string | null;
} => {
    const {date, time, timeZone, pods} = podData;

    const createdDateTime = createDate(`${date} ${time}`, {fromTimeZone: timeZone, toTimeZone: 'utc'});
    const {fullOriginalDateTime: dateTime, isValid} = createdDateTime;

    const podsForRequest = (pods || []).map((pod) => {
        const attachments = pod?.attachments || [];

        const convertedAttachments = getAttachmentsMetaData(attachments);

        const data = {signedBy: pod.signedBy, bolId: pod.bolId?.value};

        return {...data, files: convertedAttachments};
    });

    return {
        documentActionTimeZone: isValid && timeZone ? timeZone : null,
        documentActionDateTime: isValid ? dateTime : null,
        pods: podsForRequest,
    };
};

export const transformAddPODFilesToRequestBody = (params: {
    pods: Partial<{
        attachments?: formTypes.FormFileWithID[];
        bolId: {label: string; value: number};
        signedBy: string;
    }>[];
    data: {[key: string]: {fileId: number; podId: number; stopId?: string}}[];
}) => {
    const {pods, data} = params;

    const newTmpIDs = keys(data);

    const allPODsFiles = pods.map((pod) => (pod?.attachments || []).map((attachment) => attachment));
    const flattenedAllPODsFiles = allPODsFiles.flat();

    const transformPodIDsData = groupBy(
        newTmpIDs.map((tmpID) => {
            const podsFileIDs: {fileId: number; podId: number; stopId?: string} = data[tmpID];
            return set(podsFileIDs, 'tmpID', tmpID) as {fileId: number; podId: number; tmpID: string; stopId?: string};
        }),
        'podId',
    );

    const newPodIDs = keys(transformPodIDsData);

    return newPodIDs.map((podID) => {
        const podsFileIDs = transformPodIDsData[podID];
        const stopID = head(podsFileIDs)?.stopId;
        const correspondingFiles = flattenedAllPODsFiles.filter((item) =>
            podsFileIDs.find((itm) => isEqual(itm.tmpID, item.tmpID)),
        );
        const filesIds = Object.fromEntries(podsFileIDs.map((n) => [n.fileId, n.tmpID]));
        const {uploadedFormData} = getCorrespondingAttachments({formAttachments: correspondingFiles, filesIds});

        return {podID, stopID, uploadedFormData};
    });
};

export const transformPODsDataOfTheAllDeliveriesToRequestBody = (
    podData: formTypes.NotSignedPODFormValues,
): {emptyBols: {stopId?: string; pods?: {bolId?: number; signedBy?: string}[]}[]} => {
    const {deliveries} = podData;

    const transformedPODsData = deliveries.map((delivery) => {
        const podsForRequest = (delivery.podData || []).map((pod) => {
            const attachments = pod?.attachments || [];

            const convertedAttachments = getAttachmentsMetaData(attachments);

            const data = {signedBy: pod.signedBy, bolId: pod.bolId?.value};

            return {...data, files: convertedAttachments};
        });

        return {
            pods: podsForRequest,
            stopId: delivery.deliveryID?.value,
        };
    });

    return {emptyBols: transformedPODsData};
};
