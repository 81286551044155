import omit from 'lodash/omit';

import {BrokerUpdateRequestBody} from 'core/entities/LocationEvents/types';

import {BrokerUpdateSendFormValues} from 'components/common/LocationEvents/modals/BrokerUpdateSendModal/types';

export const transformToArrayEmails = (emails?: string): string[] => emails?.split(',') || [];

export const transformToStringEmails = (emails?: string[], separator?: string): string =>
    emails?.join(separator || ',') || '';

export const transformOptionsToArrayEmails = (emails?: BrokerUpdateSendFormValues['emailsTo']): string[] =>
    emails?.map?.(({value}) => value) || [];

export const transformEmailsToOptions = (emails?: string[]): BrokerUpdateSendFormValues['emailsTo'] =>
    emails?.map?.((email) => ({label: email, value: email})) || [];

export const transformBrokerUpdateDataToRequestBody = (
    brokerUpdate: BrokerUpdateSendFormValues,
): BrokerUpdateRequestBody => {
    const brokerUpdateRequestData = omit(brokerUpdate, 'travelOrderNumber');

    return {
        ...brokerUpdateRequestData,
        updateType: brokerUpdate.updateType.value,
        emailSenderId: brokerUpdate.emailSender?.value || null,
        emailsTo: transformOptionsToArrayEmails(brokerUpdate.emailsTo),
    };
};
