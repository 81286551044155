import React from 'react';
import {components} from 'react-select';
import AsyncPaginate from 'react-select-async-paginate';
import classNames from 'classnames';
import {WrappedFieldProps} from 'redux-form';

import getValidationState from 'components/ui/Form/getValidationState';
import OverlayTriggerCustom from 'components/ui/Form/OverlayTriggerCustom';
import ReactWindowWrapper from 'components/ui/ReactWindowWrapper';

import useChangeReduxFormValue from 'hooks/reduxForm/useChangeReduxFormValue';
import useClearReduxFormValue from 'hooks/reduxForm/useClearReduxFormValue';

import SelectOption from 'types/SelectOption';

import {AsyncSelectPaginateResult} from './types';

import styles from './styles.module.scss';

const DEBOUNCE_TIMEOUT = 250;

const OptionWithRightIcon = (props) => {
    const {
        data: {label, icon},
    } = props;

    return (
        <components.Option {...props}>
            <span>{label}</span>
            {icon ? (
                <>
                    &nbsp;
                    <i className={`fa ${icon}`} aria-hidden="true" />
                </>
            ) : null}
        </components.Option>
    );
};

const OptionWithLeftIcon = (props) => {
    const {
        data: {label, icon},
    } = props;

    return (
        <components.Option {...props}>
            {icon ? (
                <>
                    <i className={`fa ${icon}`} aria-hidden="true" />
                    &nbsp;
                </>
            ) : null}
            <span>{label}</span>
        </components.Option>
    );
};

const SingleValueWithRightIcon = (props) => {
    const {
        data: {label, icon},
    } = props;

    return (
        <components.SingleValue {...props}>
            <span>{label}</span>
            {icon ? (
                <>
                    &nbsp;
                    <i className={`fa ${icon}`} aria-hidden="true" />
                </>
            ) : null}
        </components.SingleValue>
    );
};

const SingleValueWithLeftIcon = (props) => {
    const {
        data: {label, icon},
    } = props;

    return (
        <components.SingleValue {...props}>
            {icon ? (
                <>
                    <i className={`fa ${icon}`} aria-hidden="true" />
                    &nbsp;
                </>
            ) : null}
            <span>{label}</span>
        </components.SingleValue>
    );
};

const OptimizedMenuList = (props) => {
    return <ReactWindowWrapper {...props} />;
};

type Option = {label: string; value: SelectOption['value']};

type OwnProps = {
    loadOptions: () => Promise<AsyncSelectPaginateResult>;

    input: WrappedFieldProps['input'];
    meta: WrappedFieldProps['meta'];
    iconPosition?: 'left' | 'right';
    isNeedOptimization?: boolean;
    closeMenuOnSelect?: boolean;
    debounceTimeout?: number;
    isFetchOnMount?: boolean;
    errorPlacement?: string;
    isClearable?: boolean;
    optionHeight?: number;
    isDisabled?: boolean;
    placeholder?: string;
};

const SingleAsyncSelect: React.FC<OwnProps> = (props) => {
    const {
        isNeedOptimization,
        closeMenuOnSelect,
        debounceTimeout,
        errorPlacement,
        isFetchOnMount,
        iconPosition,
        optionHeight,
        loadOptions,
        isClearable,
        placeholder,
        isDisabled,
        input,
        meta,
    } = props;

    const changeValue = useChangeReduxFormValue(meta.form);
    const clearValue = useClearReduxFormValue(meta.form);

    const handleChange = (option: Option | null) => {
        if (!option && meta.initial) {
            changeValue(input.name, null);
            return;
        }

        if (!option) {
            changeValue(input.name, null);
            clearValue(input.name);
            return;
        }

        changeValue(input.name, option);
    };

    const validationState = getValidationState(meta);

    const selectStyles = {
        option: (optionStyles, optionProps) => ({
            ...optionStyles,
            ...(optionProps?.isDisabled ? {pointerEvents: 'none'} : {}),
            ...(optionHeight ? {height: optionHeight, display: 'flex', flexDirection: 'column'} : {}),
        }),
    };

    const wrapClassNames = classNames(styles.wrap, {
        [styles.error]: !validationState,
        [styles.disabled]: isDisabled,
    });

    const getComponentsToRender = () => {
        if (isNeedOptimization && iconPosition === 'left') {
            return {
                Option: OptionWithLeftIcon,
                SingleValue: SingleValueWithLeftIcon,
                MenuList: OptimizedMenuList,
            };
        }

        if (isNeedOptimization && iconPosition === 'right') {
            return {
                Option: OptionWithRightIcon,
                SingleValue: SingleValueWithRightIcon,
                MenuList: OptimizedMenuList,
            };
        }

        if (iconPosition === 'right') {
            return {
                Option: OptionWithRightIcon,
                SingleValue: SingleValueWithRightIcon,
            };
        }

        return {
            Option: OptionWithLeftIcon,
            SingleValue: SingleValueWithLeftIcon,
        };
    };

    return (
        <OverlayTriggerCustom className={wrapClassNames} inputMeta={meta} placement={errorPlacement}>
            <AsyncPaginate
                debounceTimeout={debounceTimeout || DEBOUNCE_TIMEOUT}
                closeMenuOnSelect={closeMenuOnSelect}
                components={getComponentsToRender()}
                defaultOptions={isFetchOnMount}
                loadOptions={loadOptions}
                isClearable={isClearable}
                placeholder={placeholder}
                onChange={handleChange}
                isDisabled={isDisabled}
                additional={{page: 1}}
                ignoreAccents={false}
                styles={selectStyles}
                value={input.value}
                cacheUniq={true}
            />
        </OverlayTriggerCustom>
    );
};

export default SingleAsyncSelect;
