import React, {useEffect, useRef, useState} from 'react';
import debounce from 'lodash/debounce';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';

import MessageItem from 'widgets/Chat/components/MessageItem';
import useChatActions from 'widgets/Chat/hooks/useChatActions';
import useChatState from 'widgets/Chat/hooks/useChatState';

import Loader from './components/Loader';

import styles from './styles.module.scss';

const scrollToMessage = (id): void => {
    const messageEl = document.getElementById(`${id}`);

    if (messageEl) {
        messageEl.scrollIntoView({behavior: 'smooth'});
    }
};

const DEBOUNCE_TIME_OUT = 300;

type OwnProps = {
    driverName: string;
};

const ListMessages: React.FC<OwnProps> = (props) => {
    const {driverName} = props;

    const prevInnerDivHeight = useRef<number | null>(null);
    const prevFirstMessageID = useRef<string | null>(null);
    const prevLastMessageID = useRef<string | null>(null);

    const outerDiv = useRef<HTMLDivElement | null>(null);
    const innerDiv = useRef<HTMLDivElement | null>(null);

    const [isLoadingMessages, setIsLoadingMessages] = useState(false);
    const {driversGroups, openedDriversGroup, isGroupMessagesLoaded} = useChatState();
    const {getDriversGroupMoreMessages, allMessagesAreLoaded} = useChatActions();

    const {messages} = driversGroups.byCognitoUserID[openedDriversGroup.cognitoUserID];

    useEffect(() => {
        if (isEmpty(messages)) {
            return;
        }

        const {id: firstMessageID} = head(messages) || ({} as any);
        const {id: lastMessageID} = last(messages) || ({} as any);

        const prevFirstID = prevFirstMessageID.current || null;
        const prevLastID = prevLastMessageID.current || null;

        const outerDivHeight = outerDiv?.current?.clientHeight || 0;
        const innerDivHeight = innerDiv?.current?.clientHeight || 0;
        const outerDivScrollTop = outerDiv?.current?.scrollTop || 0;
        const prevInnerHeight = prevInnerDivHeight?.current || 0;

        const outerPaddings = 30; // 15 + 15 | padding-top + padding-bottom
        const outerBorders = 4; // 2 + 2 | border-top + border-bottom
        const scrollFromTopPixels = innerDivHeight - outerDivHeight + outerPaddings + outerBorders;

        const messageScrollHeight = 170;
        const scrollFromTheBottom = Math.abs(Math.round(outerDivScrollTop - (prevInnerHeight - outerDivHeight)));

        const invokeScroll = () => {
            if (!prevInnerHeight) {
                outerDiv?.current?.scrollTo({top: scrollFromTopPixels, left: 0, behavior: 'auto'});
                return;
            }

            if (prevInnerHeight && prevInnerHeight < outerDivHeight && innerDivHeight > outerDivHeight) {
                outerDiv?.current?.scrollTo({top: scrollFromTopPixels, left: 0, behavior: 'auto'});
                return;
            }

            if (prevInnerHeight && innerDivHeight > outerDivHeight && scrollFromTheBottom < messageScrollHeight) {
                outerDiv?.current?.scrollTo({top: scrollFromTopPixels, left: 0, behavior: 'smooth'});
                return;
            }

            const isPrevFetched = prevInnerHeight && lastMessageID === prevLastID && firstMessageID !== prevFirstID;

            if (isPrevFetched && innerDivHeight > outerDivHeight && outerDivScrollTop === 0) {
                scrollToMessage(prevFirstID);
            }
        };

        invokeScroll();

        prevFirstMessageID.current = firstMessageID || null;
        prevLastMessageID.current = lastMessageID || null;
        prevInnerDivHeight.current = innerDivHeight;
    }, [messages]);

    useEffect(() => {
        return () => allMessagesAreLoaded(false);
    }, []);

    useEffect(() => {
        const scrollHandler = (): void => {
            if (outerDiv.current && outerDiv.current.scrollTop === 0) {
                getDriversGroupMoreMessages({
                    setIsLoading: setIsLoadingMessages,
                    cognitoUserID: openedDriversGroup.cognitoUserID,
                });
            }
        };

        const debouncedScrollHandler = debounce(scrollHandler, DEBOUNCE_TIME_OUT);

        if (outerDiv.current) {
            outerDiv.current.addEventListener('scroll', debouncedScrollHandler, {capture: false, passive: true});
        }

        return () => {
            if (outerDiv.current) {
                outerDiv.current.removeEventListener('scroll', debouncedScrollHandler);
            }
        };
    });

    const mgs = messages.map((message) => (
        <div key={message.id} id={message.id}>
            <MessageItem message={message} />
        </div>
    ));

    const noMessagesAlert = isGroupMessagesLoaded ? (
        <div className={styles.noMessages}>This is the start of your conversation with {driverName}</div>
    ) : null;

    return (
        <div className={styles.container} ref={outerDiv}>
            <div className={styles.messages} ref={innerDiv}>
                <Loader isLoading={isLoadingMessages} />
                {noMessagesAlert}
                {mgs}
            </div>
        </div>
    );
};

export default ListMessages;
